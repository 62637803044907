import { Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { SvgIconComponent } from '../../../shared/components/svg-icon/svg-icon.component';

import { IDiscoverItem } from '../../../interfaces/discover.interfaces';
import { ISvgConfig } from '../../../shared/interfaces/svg.interfaces';

import { ESvgTypes } from '../../../shared/enums/svg.enums';

import { slideInOut } from '../../../shared/animations/slideInOut.animations';
import { fadeAnimation, fadeAnimationSequence } from '../../../shared/animations/fade.animations';

@Component({
  selector: 'stxt-creator-card',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, SvgIconComponent],
  templateUrl: './creator-card.component.html',
  styleUrl: './creator-card.component.scss',
  animations: [slideInOut, fadeAnimation, fadeAnimationSequence]
})
export class CreatorCardComponent {
  @Input() creatorItem: IDiscoverItem;
  @Input() selectedCreator: IDiscoverItem;
  public verifySvg: ISvgConfig = { fill: ESvgTypes.None, name: 'verified' };
}
