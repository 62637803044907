import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { first, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConversationService } from '../../shared/services/conversation.service';
import { DiscoverService } from '../../shared/services/discover.service';
import { MediaService } from '../../shared/services/media.service';
import { TokensService } from '../../shared/services/tokens.service';

import { CreatorsCarouselComponent } from '../../components/image-generation/creators-carousel/creators-carousel.component';
import { ButtonComponent } from 'app/shared/components/button/button.component';
import { EmptyStateComponent } from '../../components/empty-state/empty-state.component';
import { ImagePresetsComponent } from 'app/components/image-generation/image-presets/image-presets.component';
import { MediaLightboxComponent } from '../../components/media-lightbox/media-lightbox.component';
import { RegenerateImageModalComponent } from 'app/components/image-generation/regenerate-image-modal/regenerate-image-modal.component';
import { SuggestedPromptComponent } from '../../components/suggested-prompt/suggested-prompt.component';
import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';
import { ThumbnailLoaderComponent } from '../../components/image-generation/thumbnail-loader/thumbnail-loader.component';
import { ToastComponent } from '../../shared/components/toast/toast.component';

import { ClickOutsideDirective } from '../../shared/directives/click-outside.directive';

import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';
import { ICreatorPromptResponse } from '../../interfaces/conversations.interfaces';
import { IDiscoverItem, IDiscoverResponse } from '../../interfaces/discover.interfaces';
import { IEmptyStateConfig } from '../../shared/interfaces/shared.interfaces';
import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';
import { IToastConfig } from '../../shared/interfaces/toast-config.interfaces';

import { EButtonSizes, EButtonTypes } from 'app/shared/enums/button.enums';
import { EMediaLightboxUsage, EPromptUsage } from '../../shared/enums/shared.enums';
import { ESvgTypes } from 'app/shared/enums/svg.enums';
import { EToastType, EToastUsage } from '../../shared/enums/toast.enums';

import { slideInOut } from '../../shared/animations/slideInOut.animations';
import { fadeAnimation, fadeAnimationSequence } from '../../shared/animations/fade.animations';

@Component({
  selector: 'stxt-image-generation-page',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonComponent,
    SvgIconComponent,
    CreatorsCarouselComponent,
    SuggestedPromptComponent,
    ImagePresetsComponent,
    ClickOutsideDirective,
    NgOptimizedImage,
    ThumbnailLoaderComponent,
    ToastComponent,
    MediaLightboxComponent,
    EmptyStateComponent
  ],
  templateUrl: './image-generation-page.component.html',
  styleUrl: './image-generation-page.component.scss',
  animations: [slideInOut, fadeAnimation, fadeAnimationSequence]
})
export class ImageGenerationPageComponent implements OnInit, OnDestroy {
  public creators: IDiscoverItem[] = this.discoverService.creators$.getValue();
  public tokenSubscription: Subscription;
  public tokens: number;
  public selectedCreator: IDiscoverItem;
  public imageGalleryIcon: ISvgConfig = { fill: ESvgTypes.None, name: 'image-gen-gallery' };
  public filterIcon: ISvgConfig = { fill: ESvgTypes.None, name: 'filter-list' };
  public closeIcon: ISvgConfig = { fill: ESvgTypes.None, name: 'close' };
  public tokenIcon: ISvgConfig = { fill: ESvgTypes.None, name: 'token-badge' };
  public generateButton: IButtonConfig = {
    text: 'Generate',
    buttonSize: EButtonSizes.Default,
    fill: EButtonTypes.LightColored
  };
  public arrowRightIcon: ISvgConfig = { fill: ESvgTypes.None, name: 'arrow_right_full' };
  public isGeneratingImage: boolean = false;
  public isRightPanelVisible: boolean = false;
  public isShowSuggestions: boolean = false;
  public isToastMessage: boolean = false;
  public isShowFilter: boolean = false;
  public mediaFullScreen: boolean = false;
  public suggestedPrompts: string[] = [];
  public filteredPrompts: string[] = [];
  public userInput: string = '';
  public generatePrice: number = 10;
  public creatorsFilterList: string[] = [];
  public imagesToDisplay: IDiscoverItem[] = [];
  public myImagesData: IDiscoverItem[] = [];
  public toast: IToastConfig;
  public selectedMedia: IDiscoverItem;
  public postDescription: string = 'My images';
  protected readonly EPromptUsage = EPromptUsage;
  protected readonly EMediaLightboxUsage = EMediaLightboxUsage;
  public emptyStateData: IEmptyStateConfig = {
    svgName: 'images',
    heading: 'Bring ideas to life',
    subheading: 'Choose a creator and describe what you’d like to see',
    buttonText: 'Generate images'
  };

  constructor(
    private readonly discoverService: DiscoverService,
    private readonly conversationService: ConversationService,
    private readonly mediaService: MediaService,
    private readonly tokensService: TokensService,
    private readonly modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.creators === null ? this.fetchCreatorsList() : this.sortCreators(this.creators);
    this.tokenSubscription = this.tokensService.tokensBalance$.subscribe(balance => {
      this.tokens = balance;
    });
  }

  fetchCreatorsList(): void {
    this.discoverService
      .getDiscoverData()
      .pipe(first())
      .subscribe({
        next: (response: IDiscoverResponse) => {
          this.sortCreators(response.creators);
        },
        error: error => {
          console.error('Error fetching data:', error);
        }
      });
  }

  sortCreators(creators: IDiscoverItem[]): void {
    this.creators = creators;
    this.creators = this.creators
      .filter(creator => creator.ready)
      .sort((a, b) => a.name.localeCompare(b.name));
    this.selectedCreator = this.creators[0];
    this.getCreatorPrompt();
  }

  toggleRightPanel(): void {
    this.isRightPanelVisible = !this.isRightPanelVisible;
  }

  showFilters(event: Event): void {
    event.stopPropagation();
    this.isShowFilter = !this.isShowFilter;
  }

  filterImagesByCreator(creator: IDiscoverItem): void {
    const index = this.creatorsFilterList.indexOf(creator.id);
    if (index === -1) {
      this.creatorsFilterList.push(creator.id);
    } else {
      this.creatorsFilterList.splice(index, 1);
    }

    if (this.creatorsFilterList.length === 0) {
      this.imagesToDisplay = [...this.myImagesData];
    } else {
      this.imagesToDisplay = this.myImagesData.filter(imageFile =>
        this.creatorsFilterList.includes(imageFile.id)
      );
    }
  }

  isChecked(creatorId: string): boolean {
    return this.creatorsFilterList.includes(creatorId);
  }

  onImageLoad(event: Event): void {
    const img = event.target as HTMLImageElement;
    img.classList.add('loaded');
  }

  updateSelectedCreator(creator: IDiscoverItem): void {
    this.selectedCreator = creator;
    this.getCreatorPrompt();
  }

  getCreatorPrompt(): void {
    this.conversationService
      .getCreatorPrompt(this.selectedCreator.id)
      .pipe(first())
      .subscribe({
        next: (res: ICreatorPromptResponse) => {
          this.suggestedPrompts = res.prompt;
          this.filteredPrompts = res.prompt;
        },
        error: err => {
          console.log(err);
        }
      });
  }

  generateImage(): void {
    if (!this.userInput.length) return;
    else if (this.tokens < this.generatePrice) {
      this.tokensService.getMoreTokensEvent$.next(true);
    } else {
      this.isGeneratingImage = true;
      this.handleToast(EToastUsage.IMAGE_GENERATING);
      this.mediaService
        .generateImage({ prompt: this.userInput, creator_id: this.selectedCreator.id })
        .pipe(first())
        .subscribe({
          next: res => {
            // TODO - after backend implementation gonna finish this part, and commented code will be removed;
            console.log(res);
            this.handleToast(EToastUsage.IMAGE_GENERATED);
          },
          error: () => {
            this.isGeneratingImage = false;
            this.handleToast(EToastUsage.IMAGE_GENERATION_FAILED);
          }
        });
      // setTimeout(() => {
      //   {
      //     ///Used mocked data for now
      //     this.imagesToDisplay = [
      //       {
      //         chats: 0,
      //         id: 'cf0a3aa01798425da6344e0cdc791c4',
      //         description: 'Your soft, safe haven',
      //         followers: 153000,
      //         name: 'Ashley Belle',
      //         image:
      //           'https://d3do5ar74h1pnk.cloudfront.net/cf0a3aa01798425da6344e0cdc791c4d/secondary_profile_image.jpeg',
      //         secondary_image:
      //           'https://d2zqm7z35k26f9.cloudfront.net/8859f1699bce4aef9f0d6a5ee05ce670/secondary_profile_image.jpeg',
      //         media_suggestion_tags: [ 'leggings', 'bikini', 'panties', 'lingerie' ],
      //         sub_plan: {
      //           code: 'price_stg_RitPrI7HgouHPTba',
      //           price: 499
      //         },
      //         ready: true
      //       },
      //       ...this.imagesToDisplay
      //     ];
      //   }
      // }, 9000);
    }
  }

  handleToast(toastUsage: EToastUsage): void {
    this.isToastMessage = true;

    switch (toastUsage) {
      case EToastUsage.IMAGE_GENERATED:
        this.toast = {
          toastHeading: 'Your image is ready',
          toastSubheading: `You have ${this.tokens} tokens left.`,
          toastType: EToastType.SUCCESS
        };
        break;
      case EToastUsage.IMAGE_GENERATING:
        this.toast = {
          toastHeading: 'Your image is on the way',
          toastSubheading: `Please stay on this page as we create your image.`,
          toastType: EToastType.WARN
        };
        break;
      case EToastUsage.IMAGE_GENERATION_FAILED:
        this.toast = {
          toastHeading: 'Image could not be generated',
          toastType: EToastType.FAIL
        };
        break;
    }
  }

  openMediaFullScreen(selectedMedia: IDiscoverItem): void {
    this.selectedMedia = selectedMedia;
    this.mediaFullScreen = true;
  }

  openRegenerateModal(selectedMedia: IDiscoverItem): void {
    this.selectedMedia = selectedMedia;
    const regenerateModal = this.modalService.open(RegenerateImageModalComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    regenerateModal.closed.subscribe((next: string) => {
      if (next !== 'regenerate') return;
      this.confirmRegenerate();
    });
  }

  confirmRegenerate(): void {
    const targetImage = this.imagesToDisplay.find(image => image.id === this.selectedMedia.id);
    this.mediaFullScreen = false;
    this.handleToast(EToastUsage.IMAGE_GENERATING);

    if (targetImage) {
      this.isGeneratingImage = true;
      targetImage.image = this.selectedMedia.image;
      this.isGeneratingImage = false;
      this.handleToast(EToastUsage.IMAGE_GENERATED);
    }
  }

  onInputChange(input: string): void {
    const trimmedInput = input.trim().toLowerCase();
    this.userInput = trimmedInput;
    this.filteredPrompts = trimmedInput
      ? this.suggestedPrompts?.filter(prompt => prompt.toLowerCase().includes(trimmedInput))
      : [...this.suggestedPrompts];
  }

  selectPrompt(suggestedItem: string): void {
    this.userInput = suggestedItem;
    this.isShowSuggestions = false;
  }

  ngOnDestroy(): void {
    this.tokenSubscription.unsubscribe();
  }
}
