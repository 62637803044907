import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ButtonComponent } from 'app/shared/components/button/button.component';
import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';
import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';

import { EButtonSizes, EButtonTypes } from 'app/shared/enums/button.enums';
import { ESvgTypes } from 'app/shared/enums/svg.enums';

@Component({
  selector: 'stxt-regenrate-image-modal',
  standalone: true,
  imports: [SvgIconComponent, ButtonComponent],
  templateUrl: './regenerate-image-modal.component.html',
  styleUrl: './regenerate-image-modal.component.scss'
})
export class RegenerateImageModalComponent {
  public regenerateIcon: ISvgConfig = { fill: ESvgTypes.None, name: 'regenerate' };
  public cancelButton: IButtonConfig = {
    text: 'Cancel',
    buttonSize: EButtonSizes.Default,
    fill: EButtonTypes.Empty
  };
  public regenerateButton: IButtonConfig = {
    text: 'Regenerate',
    buttonSize: EButtonSizes.Default,
    fill: EButtonTypes.LightColored
  };

  constructor(private readonly activeModal: NgbActiveModal) {}

  public confirmRegenerate(): void {
    this.activeModal.close('regenerate');
  }

  public cancel(): void {
    this.activeModal.dismiss();
  }
}
