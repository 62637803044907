import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { catchError, concatMap, first, of } from 'rxjs';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';

import { PaymentsService } from 'app/shared/services/payments.service';
import { ProfileService } from '../../../shared/services/profile.service';
import { TokensService } from '../../../shared/services/tokens.service';

import { SvgIconComponent } from '../../../shared/components/svg-icon/svg-icon.component';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { PaymentFormComponent } from '../payment-form/payment-form.component';
import { CodeVerifyComponent } from '../code-verify/code-verify.component';
import { PaymentSucceedComponent } from '../payment-succeed/payment-succeed.component';
import { CheckoutAdditionalInfoComponent } from './checkout-additional-info/checkout-additional-info.component';
import { RecaptchaTermsComponent } from '../../../shared/components/recaptcha-terms/recaptcha-terms.component';
import { LoadingComponent } from 'app/shared/components/loading/loading.component';
import { PaymentFailedComponent } from '../payment-failed/payment-failed.component';

import { ISvgConfig } from '../../../shared/interfaces/svg.interfaces';
import { IButtonConfig } from '../../../shared/interfaces/button.interfaces';
import { IBillingInfo, IUserProfile } from '../../../interfaces/profile.interfaces';
import {
  IPaymentErrorMessage,
  IPaymentResult,
  IPurchaseData,
  ISubscriptionData
} from 'app/interfaces/payments.interfaces';
import { ITokenPlan } from 'app/shared/interfaces/token.interfaces';

import { ESvgTypes } from '../../../shared/enums/svg.enums';
import { EButtonSizes, EButtonTypes } from '../../../shared/enums/button.enums';
import { EPaymentErrorTitle, EPaymentStatus, EPaymentType } from 'app/enums/payments.enums';
import { ETermsOfService } from 'app/enums/terms-of-service.enums';
import { ELoadingTexts } from 'app/shared/enums/loading-texts.enums';

@Component({
  selector: 'stxt-checkout',
  standalone: true,
  imports: [
    CommonModule,
    NgbModalModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    SvgIconComponent,
    ButtonComponent,
    PaymentFormComponent,
    CodeVerifyComponent,
    CheckoutAdditionalInfoComponent,
    RecaptchaTermsComponent,
    LoadingComponent,
    PaymentFailedComponent
  ],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss',
  providers: [ReCaptchaV3Service]
})
export class CheckoutComponent implements OnInit {
  @Input() firstCheckOut: boolean;
  @Input() paymentType: string;
  @Input() creator: string;
  @Input() creatorId: string;
  @Input() subscriptionData?: ISubscriptionData;
  @Input() purchaseData?: IPurchaseData;
  @Input() tokenData?: ITokenPlan;
  @Input() billingData: IBillingInfo[];
  @Input() wsConnectionId: string;
  @Output() closeCheckoutEvent: EventEmitter<IPaymentResult> = new EventEmitter<IPaymentResult>();
  @Output() openPaymentList: EventEmitter<void> = new EventEmitter<void>();
  @Output() isEditingTokens: EventEmitter<boolean> = new EventEmitter<boolean>();
  public editTokensSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'pen' };
  public confirmBtn: IButtonConfig = {
    text: 'Confirm purchase',
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Default
  };
  public termsForm: FormGroup;
  public user = JSON.parse(localStorage.getItem('user'));
  public userProfile: IUserProfile;
  public verificationState = {
    isVerified: false,
    isVerifying: false
  };
  public readonly EPaymentType = EPaymentType;
  public readonly ETermsOfService = ETermsOfService;
  public readonly ELoadingTexts = ELoadingTexts;
  public isPaymentFormValid: boolean;
  public showPaymentProcessing: boolean = false;
  public paymentErrorMessage: IPaymentErrorMessage;
  public isPaymentError: boolean = false;

  constructor(
    public modal: NgbModal,
    public formBuilder: FormBuilder,
    public paymentsService: PaymentsService,
    public profileService: ProfileService,
    private readonly tokensService: TokensService,
    public recaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit(): void {
    this.getCurrentUser();
    this.initTermsForm();
    if (this.subscriptionData === undefined) {
      this.subscriptionData = { price: 500, code: '' };
    }
    this.isPaymentFormValid = !!this.billingData;
  }

  getCurrentUser(): void {
    this.profileService
      .getProfileData()
      .pipe(first())
      .subscribe(profile => {
        this.userProfile = profile;
        if (!profile.email_verified) {
          this.verificationState.isVerifying = true;
        }
        this.verificationState.isVerified = profile.email_verified;
      });
  }

  initTermsForm(): void {
    this.termsForm = this.formBuilder.group({
      acceptTerms: [false, Validators.requiredTrue]
    });
  }

  setUserVerified(): void {
    this.verificationState.isVerified = true;
  }

  onFormValidityChange(isError: boolean): void {
    this.isPaymentFormValid = !isError;
  }

  confirmPayment(): void {
    this.recaptchaV3Service
      .execute('importantAction')
      .pipe(first())
      .subscribe({
        next: () => {
          if (this.termsForm.invalid) return;
          this.showPaymentProcessing = this.isPaymentFormValid;
          this.handlePaymentConfirmCheck();
        },
        error: error => {
          console.log(error);
        }
      });
  }

  handlePaymentConfirmCheck(): void {
    if (this.billingData) {
      switch (this.paymentType) {
        case EPaymentType.Subscription:
          this.subscriptionPayment(this.billingData[0].id);
          break;
        case EPaymentType.Purchase:
          this.purchasePayment(this.billingData[0].id);
          break;
        case EPaymentType.Token:
          this.tokenPurchase(this.billingData[0].id);
          break;
      }
    } else {
      this.paymentsService.$submitPayment.next(true);
    }
  }

  tokenPurchase(id: string): void {
    this.tokensService
      .purchaseTokens({
        billing_token: id,
        tokens_pack_id: this.tokenData.tokens_pack_id,
        quantity: this.tokenData.quantity
      })
      .pipe(
        first(),
        concatMap(() =>
          this.tokensService.fetchTokensBalance(true).pipe(
            catchError(error => {
              console.error('Error fetching token balance:', error);
              return of(null);
            })
          )
        )
      )
      .subscribe({
        next: () => {
          this.initResultEvent(EPaymentStatus.Success, EPaymentType.Token);
          this.showPaymentProcessing = false;
        },
        error: () => {
          this.initResultEvent(EPaymentStatus.Error, EPaymentType.Token);
          this.showPaymentProcessing = false;
        }
      });
  }

  subscriptionPayment(id: string): void {
    this.paymentsService
      .creatorSubscriptionRequest({
        creator_id: this.creatorId,
        billing_token: id,
        plan_code: this.subscriptionData.code,
        ws_conn_id: this.wsConnectionId
      })
      .pipe(first())
      .subscribe({
        next: () => {
          this.initResultEvent(EPaymentStatus.Success, EPaymentType.Subscription);
          this.showPaymentProcessing = false;
        },
        error: err => {
          if (err.status === 200) {
            this.initResultEvent(EPaymentStatus.Success, EPaymentType.Subscription);
          } else {
            this.checkPaymentResult({
              status: EPaymentStatus.Error,
              paymentType: EPaymentType.Subscription,
              error_message: err.error.error_message
            });
          }
          this.showPaymentProcessing = false;
        }
      });
  }

  purchasePayment(id: string): void {
    this.paymentsService
      .purchaseMediaRequest({
        creator_id: this.creatorId,
        billing_token: id,
        message_timestamp: Number(this.purchaseData.message_timestamp),
        ws_conn_id: this.wsConnectionId
      })
      .pipe(first())
      .subscribe({
        next: () => {
          this.initResultEvent(EPaymentStatus.Success, EPaymentType.Purchase);
          this.showPaymentProcessing = false;
        },
        error: err => {
          if (err.status === 200) {
            this.initResultEvent(EPaymentStatus.Success, EPaymentType.Purchase);
          } else {
            this.checkPaymentResult({
              status: EPaymentStatus.Error,
              paymentType: EPaymentType.Purchase,
              error_message: err.error.error_message
            });
          }
          this.showPaymentProcessing = false;
        }
      });
  }

  checkPaymentResult(paymentResult: IPaymentResult): void {
    if (paymentResult.status === EPaymentStatus.Success) {
      this.handlePaymentSuccess(paymentResult);
    } else {
      this.handlePaymentError(paymentResult);
    }
  }

  handlePaymentSuccess(paymentResult: IPaymentResult): void {
    this.initResultEvent(EPaymentStatus.Success, paymentResult.paymentType);
    if (this.paymentType === EPaymentType.Token) return;
    const modalRef = this.modal.open(PaymentSucceedComponent);
    modalRef.componentInstance.paymentType = paymentResult.paymentType;
  }

  handlePaymentError(paymentResult: IPaymentResult): void {
    this.isPaymentError = true;
    this.paymentErrorMessage = {
      errorTitle: EPaymentErrorTitle.generic,
      errorMessage: paymentResult.error_message
    };
    if (this.paymentType === EPaymentType.Token) {
      this.initResultEvent(EPaymentStatus.Error, EPaymentType.Token);
    }
    this.showPaymentProcessing = false;
  }

  initResultEvent(status: EPaymentStatus, paymentType: EPaymentType | string): void {
    this.closeCheckoutEvent.emit({
      status: status,
      paymentType: paymentType
    });
  }
}
