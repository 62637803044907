import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  SimpleChanges,
  HostListener,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxMasonryModule } from 'ngx-masonry';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';

import { CreatorService } from 'app/shared/services/creator.service';
import { ConversationService } from 'app/shared/services/conversation.service';

import { ButtonComponent } from 'app/shared/components/button/button.component';
import { CreatorMediaCardComponent } from 'app/components/creator-media-card/creator-media-card.component';
import { EmptyStateComponent } from '../../components/empty-state/empty-state.component';
import { LoadingComponent } from 'app/shared/components/loading/loading.component';
import { MasonryGalleryComponent } from 'app/shared/components/masonry-gallery/masonry-gallery.component';
import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';
import { IConversationSharedMediaResponse } from 'app/interfaces/conversations.interfaces';
import { ICreatorData, ICreatorDiscoverData } from 'app/interfaces/creator.interfaces';
import { IEmptyStateConfig } from '../../shared/interfaces/shared.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';
import { EFriendshipTab } from 'app/shared/enums/friendship.enums';

@Component({
  selector: 'stxt-friendship-panel',
  standalone: true,
  imports: [
    CommonModule,
    NgxMasonryModule,
    ButtonComponent,
    CreatorMediaCardComponent,
    EmptyStateComponent,
    LoadingComponent,
    MasonryGalleryComponent,
    SvgIconComponent
  ],
  templateUrl: './friendship-panel.component.html',
  styleUrl: './friendship-panel.component.scss'
})
export class FriendshipPanelComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() friendsSince: Date;
  @Input() isFriendshipOpen: boolean;
  @Input() creatorData: ICreatorDiscoverData;
  @Output() closeFriendshipPage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() requestPhotos: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('parallaxImage') parallaxImage: ElementRef;
  @ViewChild('parallaxContainer') parallaxContainer: ElementRef;

  public selectedTab: EFriendshipTab = EFriendshipTab.Profile;
  public EFriendshipTab = EFriendshipTab;
  public creatorProfile: ICreatorData;
  public sharedMedia: IConversationSharedMediaResponse;
  private scrollSubscription: Subscription;
  public ageIcon: ISvgConfig = { name: 'birthday', fill: ESvgTypes.None };
  public heightIcon: ISvgConfig = { name: 'height', fill: ESvgTypes.None };
  public profileIcon: ISvgConfig = { name: 'profile', fill: ESvgTypes.None };
  public timelineIcon: ISvgConfig = { name: 'favorite', fill: ESvgTypes.None };
  public ethnicityIcon: ISvgConfig = { name: 'ethnicity', fill: ESvgTypes.None };
  public closeIcon: ISvgConfig = { name: 'close', fill: ESvgTypes.Tonal };
  public userId: string = localStorage.getItem('userId');
  public ticking: boolean = false;
  public offset: string;
  public emptyStateData: IEmptyStateConfig = {
    svgName: 'friendship-timeline',
    heading: 'Make memories together',
    subheading: `See all your shared moments and content with `,
    buttonText: 'Request photos'
  };

  constructor(
    private readonly creatorService: CreatorService,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly conversationService: ConversationService
  ) {}

  ngOnInit(): void {
    this.emptyStateData.subheading = this.emptyStateData.subheading + this.creatorData.name;
  }

  ngAfterViewInit(): void {
    this.initScrollListener();
  }

  ngOnDestroy(): void {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['creatorData']?.currentValue?.id) {
      this.getCreatorData(changes['creatorData']?.currentValue?.id);
      this.getConversationSharedMedia(changes['creatorData']?.currentValue?.id);
    }
  }

  getCreatorData(id: string): void {
    this.creatorService
      .getCreatorData(id)
      .pipe(first())
      .subscribe((creatorProfile: ICreatorData) => {
        this.creatorProfile = creatorProfile;
      });
  }

  getConversationSharedMedia(id: string): void {
    this.conversationService
      .getConversationSharedMedia(id)
      .pipe(first())
      .subscribe((mediaData: IConversationSharedMediaResponse) => {
        if (!mediaData.media) return;
        // TODO: Remove vault_id from response, ask backend to return media_id instead;
        this.sharedMedia = {
          media: mediaData.media.map(media => ({ ...media, media_id: media.vault_id })),
          total: mediaData.total,
          offset: mediaData.offset
        };
      });
  }

  selectTab(tab: EFriendshipTab): void {
    this.selectedTab = tab;
  }

  @HostListener('scroll', ['$event'])
  onScroll(): void {
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.updateParallaxEffect();
        this.ticking = false;
      });
      this.ticking = true;
    }
  }

  private initScrollListener(): void {
    this.scrollSubscription = fromEvent(this.elementRef.nativeElement, 'scroll')
      .pipe(debounceTime(10), distinctUntilChanged())
      .subscribe(() => {
        this.updateParallaxEffect();
      });
  }

  private updateParallaxEffect(): void {
    const scrollPosition = this.elementRef.nativeElement.scrollTop;
    const maxScroll = 300;
    const progress = Math.min(scrollPosition / maxScroll, 1);
    const startOpacity = 0.2;
    const endOpacity = 0.8;
    const opacity = startOpacity + progress * (endOpacity - startOpacity);
    const blur = progress * 10;
    const gradient = `radial-gradient(50% 50% at 50% 50%, rgba(26, 20, 26, ${opacity}) 0%, rgba(26, 20, 26, 1) 120%)`;

    if (this.parallaxImage?.nativeElement) {
      this.renderer.setStyle(this.parallaxImage.nativeElement, 'filter', `blur(${blur}px)`);

      this.renderer.setStyle(this.parallaxImage.nativeElement, 'opacity', 1 - progress * 1);
    } else {
      console.warn('parallaxImage not found');
    }

    if (this.parallaxContainer?.nativeElement) {
      this.renderer.setStyle(this.parallaxContainer.nativeElement, '--parallax-gradient', gradient);
    } else {
      console.warn('parallaxContainer not found');
    }
  }
}
