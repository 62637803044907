export enum EChatStates {
  ONBOARDING_FREEBIE = 'onboardingFreebie',
  FREEFLOW = 'freeflow',
  WAITING_FOR_SUBSCRIPTION = 'waitingForSubscription'
}

export enum EChatFeatureAction {
  RELOAD = 'Reload',
  CLOSE = 'Close',
  SURPRISE_ME = 'Surprise Me',
  SELECTED_TAG = 'Selected Tag'
}

export enum EFeatureEvent {
  REQUEST_MEDIA_OPEN = 'requestMediaOpen',
  REQUEST_MEDIA_CLOSE = 'requestMediaClose',
  HandleSendTag = 'handleSendTag'
}

export enum EMessageItemCategory {
  MEDIAS = 'medias',
  MESSAGE = 'message',
  COMMAND = 'command',
  EVENT = 'event'
}

export enum EMessagePayloadEvent {
  SUBSCRIPTION_COMPLETED = 'subscription_completed',
  SUBSCRIPTION_PAYWALL_ON = 'subscription_paywall_on',
  TYPING_INDICATOR_ON = 'typing_indicator_on',
  TYPING_INDICATOR_OFF = 'typing_indicator_off'
}
