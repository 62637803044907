<ng-container *ngIf="!isEditingTokens; else editingTokens">
  <div class="tokens_wrapper">
    <div [hidden]="showCheckoutComponent" class="tokens-card">
      <div class="tokens-card_balance">
        <stxt-token-status
          #tokensStatusComponent
          [usageCase]="ETokenUsageCase.Card"
        ></stxt-token-status>
      </div>
      <div class="tokens-card_tabs">
        <button
          *ngFor="let plan of plans"
          class="tokens-card_tabs__tab"
          [ngClass]="{ 'tokens-card_tabs__tab_active': selectedPlan === plan.name }"
          (click)="selectPlan(plan.name)"
        >
          {{ plan.name }}
        </button>
      </div>

      <div class="tokens-card_content" *ngIf="activePlan">
        <div class="tokens-card_content__info">
          <div class="tokens-card_content__amount">
            <div class="dot"></div>
            {{ activePlan.tokens }}
          </div>
          <div class="tokens-card_content__discount" *ngIf="activePlan.save !== '0%'">
            <p>SAVE {{ activePlan.save }}</p>
            <span>${{ activePlan.price / 100 }} • ${{ activePlan.pricePerToken }}/token</span>
          </div>
        </div>
        <stxt-button
          class="tokens-card_content__button"
          [buttonConfig]="buyNowButton"
          (click)="showCheckout()"
          (keydown.enter)="showCheckout()"
        >
          {{ buyNowButton.text }}
        </stxt-button>
      </div>
    </div>
  </div>
  <stxt-checkout
    *ngIf="showCheckoutComponent"
    [billingData]="billingData"
    [firstCheckOut]="true"
    [tokenData]="activePlan"
    [paymentType]="EPaymentType.Token"
    (isEditingTokens)="isEditingTokens = $event"
    (closeCheckoutEvent)="updateTokensScreen($event)"
  ></stxt-checkout>
</ng-container>

<ng-template #editingTokens>
  <div class="tokens-edit">
    <div class="tokens-edit_header">
      <p>Get tokens</p>
      <span>How many tokens would you like to add to your wallet:</span>
    </div>
    <div class="tokens-edit_content">
      <div class="tokens-edit_grid">
        <div
          *ngFor="let plan of plans"
          class="tokens-edit_plan"
          [ngClass]="{ 'tokens-edit_plan__selected': selectedPlan === plan.name }"
          (click)="selectedPlan = plan.name"
          (keydown.enter)="selectedPlan = plan.name"
          tabindex="-1"
        >
          <div class="flex justify-content-between w-full">
            <div class="tokens-edit_plan__type">
              <span>{{ plan.name }}</span>
            </div>
            <div class="tokens-edit_plan__radio">
              <div
                class="radio-outer"
                [ngClass]="{ 'radio-outer__selected': selectedPlan === plan.name }"
              >
                <div
                  class="radio-inner"
                  [ngClass]="{ 'radio-inner__selected': selectedPlan === plan.name }"
                ></div>
              </div>
            </div>
          </div>
          <div class="tokens-edit_plan__ammount">
            <div class="tokens-edit">
              <div class="dot"></div>
              <p>{{ plan.tokens }}</p>

              <div class="tokens-edit_plan__save" *ngIf="plan.save !== '0%'">
                SAVE {{ plan.save }}
              </div>
            </div>
            <div class="tokens-edit_plan__price">
              <span class="per-token">${{ plan.pricePerToken }}/token</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tokens-edit_footer">
      <p class="tokens-edit_footer__heading">What tokens get you</p>
      <div class="tokens-edit_footer__list" *ngFor="let feature of tokenFeatures">
        <stxt-svg-icon [iconConfig]="checkSvg" class="check-svg"></stxt-svg-icon>
        <p class="tokens-edit_footer__list_item">{{ feature.mainText }}</p>
        <p class="tokens-edit_footer__list_item">{{ feature.subText }}</p>
      </div>
    </div>
    <stxt-button
      [buttonConfig]="confirmBtn"
      (click)="confirmEditingTokens(selectedPlan)"
      (keydown.enter)="confirmEditingTokens(selectedPlan)"
      class="tokens-edit_footer__button"
    >
      {{ confirmBtn.text }}
    </stxt-button>
  </div>
</ng-template>
