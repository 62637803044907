import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  NgbCarousel,
  NgbCarouselConfig,
  NgbCarouselModule,
  NgbSlide
} from '@ng-bootstrap/ng-bootstrap';

import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';
import { MediaVideoComponent } from '../creator-media-card/media-type/media-video/media-video.component';

import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';
import { ICreatorContent, ICreatorData } from '../../interfaces/creator.interfaces';
import { IDiscoverItem } from '../../interfaces/discover.interfaces';

import { ESvgTypes } from '../../shared/enums/svg.enums';
import { ECreatorMediaTypes, ECreatorVideoTypes } from '../../enums/creator.enums';
import { ECarouselNavigation, EMediaLightboxUsage } from '../../shared/enums/shared.enums';

@Component({
  selector: 'stxt-media-lightbox',
  standalone: true,
  imports: [
    NgbCarouselModule,
    CommonModule,
    NgbCarousel,
    NgbSlide,
    SvgIconComponent,
    MediaVideoComponent
  ],
  templateUrl: './media-lightbox.component.html',
  styleUrl: './media-lightbox.component.scss'
})
export class MediaLightboxComponent implements OnInit, AfterViewInit {
  @Input() mediaList: ICreatorContent[];
  @Input() generatedMediaList: IDiscoverItem[];
  @Input() usageCase: EMediaLightboxUsage;
  @Input() creator?: ICreatorData;
  @Input() selectedMedia?: ICreatorContent;
  @Input() selectedGeneratedMedia?: IDiscoverItem;
  @Input() postDescription: string;
  @Output() closeFullScreen: EventEmitter<void> = new EventEmitter<void>();
  @Output() regenerateImage: EventEmitter<IDiscoverItem> = new EventEmitter<IDiscoverItem>();
  @ViewChild(MediaVideoComponent) videoComponent?: MediaVideoComponent;

  @ViewChild('carousel') set carouselRef(carousel: NgbCarousel) {
    if (carousel) {
      this.carousel = carousel;
    }
  }

  private carousel: NgbCarousel;
  public imagesToDisplay: IDiscoverItem[] | ICreatorContent[];
  public closeSvg: ISvgConfig = { fill: ESvgTypes.OutlinedDark, name: 'close' };
  public leftNavigation: ISvgConfig = { fill: ESvgTypes.OutlinedDark, name: 'arrow_left' };
  public rightNavigation: ISvgConfig = { fill: ESvgTypes.OutlinedDark, name: 'arrow_right' };
  public tokenIcon: ISvgConfig = { fill: ESvgTypes.None, name: 'token-badge' };
  protected readonly ECreatorMediaTypes = ECreatorMediaTypes;
  protected readonly ECreatorVideoTypes = ECreatorVideoTypes;
  protected readonly ECarouselNavigation = ECarouselNavigation;
  protected readonly EMediaLightboxUsage = EMediaLightboxUsage;

  constructor(
    config: NgbCarouselConfig,
    private cdr: ChangeDetectorRef
  ) {
    config.wrap = false;
    config.interval = 0;
    config.keyboard = false;
    config.animation = false;
  }

  ngOnInit(): void {
    this.imagesToDisplay =
      this.usageCase === EMediaLightboxUsage.MasonryGallery
        ? this.mediaList
        : this.generatedMediaList;
  }

  ngAfterViewInit(): void {
    const mediaId: string =
      this.usageCase === EMediaLightboxUsage.MasonryGallery
        ? this.selectedMedia?.media_id
        : this.selectedGeneratedMedia?.id;
    this.carousel.select(`ngb-slide-${mediaId}`);
    this.cdr.detectChanges();
  }

  carouselNavigation(control: ECarouselNavigation, media: ICreatorContent): void {
    this.checkElementType(media);
    switch (control) {
      case ECarouselNavigation.Previous:
        this.carousel.prev();
        break;
      case ECarouselNavigation.Next:
        this.carousel.next();
        break;
      default:
        console.warn(`Invalid navigation control: ${control}`);
    }
  }

  onRegenerateImage(): void {
    this.regenerateImage.emit(this.selectedGeneratedMedia);
  }

  checkElementType(media: ICreatorContent): void {
    if (media.type !== ECreatorMediaTypes.Video) return;
    this.videoComponent.pauseVideo();
  }
}
