import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';

import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';

import { ESvgTypes } from '../../shared/enums/svg.enums';
import { EPromptUsage } from '../../shared/enums/shared.enums';

@Component({
  selector: 'stxt-suggested-prompt',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './suggested-prompt.component.html',
  styleUrl: './suggested-prompt.component.scss'
})
export class SuggestedPromptComponent implements OnChanges {
  @Input() suggestedPrompt: string;
  @Input() userInput!: string;
  @Input() usageCase: EPromptUsage;
  public sendIcon: ISvgConfig = { name: 'interactive', fill: ESvgTypes.None };
  public highlightedPrompt!: string;
  protected readonly EPromptUsage = EPromptUsage;

  ngOnChanges(): void {
    this.highlightedPrompt = this.getHighlightedText(this.suggestedPrompt, this.userInput);
  }

  private getHighlightedText(text: string, input: string): string {
    if (!input) {
      return text;
    }
    const regex = new RegExp(`(${this.escapeRegex(input)})`, 'gi');
    return text.replace(regex, '<strong class="highlight">$1</strong>');
  }

  private escapeRegex(input: string): string {
    return input.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  }
}
