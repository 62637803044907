import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { OneTimePurchaseCardComponent } from './one-time-purchase-card/one-time-purchase-card.component';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { PurchasesSubscriptionCardComponent } from './purchases-subscription-card/purchases-subscription-card.component';

import {
  IBillingHistory,
  ITabChangedEvent,
  IUserSubscription
} from 'app/interfaces/profile.interfaces';
import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';

import { EButtonTypes, EButtonSizes } from 'app/shared/enums/button.enums';

@Component({
  selector: 'stxt-purchases',
  standalone: true,
  imports: [
    NgbNavModule,
    CommonModule,
    ButtonComponent,
    PurchasesSubscriptionCardComponent,
    OneTimePurchaseCardComponent
  ],
  templateUrl: './purchases.component.html',
  styleUrl: './purchases.component.scss'
})
export class PurchasesComponent {
  @Input() subscriptionsList: IUserSubscription[];
  @Input() oneTimePurchase: IBillingHistory[];
  @Output() openSubscriptionManagementEvent: EventEmitter<IUserSubscription> =
    new EventEmitter<IUserSubscription>();
  @Output() openTransactionInfoEvent: EventEmitter<IBillingHistory> =
    new EventEmitter<IBillingHistory>();
  @Output() closeProfileEvent: EventEmitter<void> = new EventEmitter<void>();

  public discoverBtnConfig: IButtonConfig = {
    text: 'Discover',
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Large
  };
  active: number = 1;

  onTabChange(event: ITabChangedEvent): void {
    this.active = event.nextId;
  }

  isActive(tabIndex: number): boolean {
    return this.active === tabIndex;
  }
}
