import { Component } from '@angular/core';

@Component({
  selector: 'stxt-thumbnail-loader',
  standalone: true,
  imports: [],
  templateUrl: './thumbnail-loader.component.html',
  styleUrl: './thumbnail-loader.component.scss'
})
export class ThumbnailLoaderComponent {}
