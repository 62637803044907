import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvgIconComponent } from '../svg-icon/svg-icon.component';

import { ISvgConfig } from '../../interfaces/svg.interfaces';
import { IToastConfig } from '../../interfaces/toast-config.interfaces';

import { ESvgTypes } from '../../enums/svg.enums';
import { EToastType } from '../../enums/toast.enums';

import { SUPPORT_LINK } from '../../const/app.constants';
import { fadeAnimation, fadeAnimationSequence } from '../../animations/fade.animations';

@Component({
  selector: 'stxt-toast',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
  animations: [fadeAnimationSequence, fadeAnimation]
})
export class ToastComponent implements OnChanges {
  @Input() toast: IToastConfig;
  @Output() closeToast: EventEmitter<void> = new EventEmitter<void>();
  public toastIcon: ISvgConfig;
  public closeIcon: ISvgConfig = { name: 'close', fill: ESvgTypes.None };

  protected readonly EToastType = EToastType;
  private timer: ReturnType<typeof setTimeout> | null = null;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['toast']) {
      this.toastIcon = this.getIcon();
      this.updateTimer();
    }
  }

  updateTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.closeToast.emit();
    }, 5000);
  }

  getIcon(): ISvgConfig {
    let svgName: string;
    switch (this.toast.toastType) {
      case EToastType.SUCCESS:
        svgName = 'verify';
        break;
      case EToastType.FAIL:
        svgName = 'info';
        break;
      case EToastType.WARN:
        svgName = 'processing';
        break;
      default:
        svgName = 'info';
    }
    return { name: svgName, fill: ESvgTypes.None };
  }

  openSupport(): void {
    window.open(SUPPORT_LINK, '_blank');
  }
}
