<div class="creators-carousel_container relative">
  <div class="gradient-overlay left"></div>

  <div class="creators-carousel scrollbar-hide" (scroll)="onScroll()" #carousel>
    <stxt-creator-card
      *ngFor="let creator of creators"
      class="creators-carousel_item"
      [creatorItem]="creator"
      [selectedCreator]="selectedCreator"
      (click)="selectCreator(creator)"
      (keydown.enter)="selectCreator(creator)"
    ></stxt-creator-card>
  </div>

  <div class="gradient-overlay right"></div>

  <div class="creators-carousel_nav button-prev flex">
    <button
      class="creators-carousel_nav__button btn-nostyle"
      [disabled]="!canScrollPrev"
      (click)="scroll(-1)"
    >
      <stxt-svg-icon [iconConfig]="leftNavigation"></stxt-svg-icon>
    </button>
  </div>
  <div class="creators-carousel_nav button-next flex">
    <button
      class="creators-carousel_nav__button btn-nostyle"
      [disabled]="!canScrollNext"
      (click)="scroll(1)"
    >
      <stxt-svg-icon [iconConfig]="rightNavigation"></stxt-svg-icon>
    </button>
  </div>
</div>
