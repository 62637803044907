import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonComponent } from '../../shared/components/button/button.component';
import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';

import { IButtonConfig } from '../../shared/interfaces/button.interfaces';
import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';
import { IEmptyStateConfig } from '../../shared/interfaces/shared.interfaces';

import { EButtonSizes, EButtonTypes } from '../../shared/enums/button.enums';
import { ESvgTypes } from '../../shared/enums/svg.enums';

@Component({
  selector: 'stxt-empty-state',
  standalone: true,
  imports: [CommonModule, ButtonComponent, SvgIconComponent],
  templateUrl: './empty-state.component.html',
  styleUrl: './empty-state.component.scss'
})
export class EmptyStateComponent implements OnInit {
  @Input() emptyStateData: IEmptyStateConfig;
  @Output() emptyStateButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  public emptyStateButton: IButtonConfig = {
    fill: EButtonTypes.Colored,
    buttonSize: EButtonSizes.Small
  };
  public emptyStateSvg: ISvgConfig = { fill: ESvgTypes.Tonal, name: '' };

  ngOnInit(): void {
    this.emptyStateSvg = { fill: ESvgTypes.Tonal, name: this.emptyStateData.svgName };
  }
}
