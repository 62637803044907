export enum ESubscriptionState {
  Active = 'active',
  Soon = 'soon',
  Expired = 'expired'
}

export enum ESubscriptionManagerPart {
  Billing_Info = 'billing_info',
  Billing_History = 'billing_history',
  Transaction_Info = 'transaction_info'
}

export enum EPurchaseState {
  Paid = 'paid',
  Canceled = 'canceled'
}

export enum EPurchaseType {
  Tokens = 'tokens',
  Images = 'images'
}
