import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';
import { ButtonComponent } from 'app/shared/components/button/button.component';

import { ITutorialStep } from 'app/interfaces/conversations.interfaces';
import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';
import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';

import { EButtonTypes } from 'app/shared/enums/button.enums';
import { ESvgTypes } from 'app/shared/enums/svg.enums';

@Component({
  selector: 'stxt-image-generation-modal',
  standalone: true,
  imports: [SvgIconComponent, CommonModule, ButtonComponent],
  templateUrl: './image-generation-modal.component.html',
  styleUrl: './image-generation-modal.component.scss'
})
export class ImageGenerationModalComponent {
  currentStep = 0;
  nextIcon: ISvgConfig = { name: 'arrow_right_full', fill: ESvgTypes.Filled };
  closeIcon: ISvgConfig = { name: 'done', fill: ESvgTypes.None };
  closeButton: IButtonConfig = { text: 'Got it', fill: EButtonTypes.LightColored };
  steps: ITutorialStep[] = [
    {
      title: 'Step 1',
      description: 'Start by pressing “Request photos” :',
      image: 'assets/d2c-images/image-gen-tutorial-1.png'
    },
    {
      title: 'Step 2',
      description: 'Tell me what you want to see, like this 😉 :',
      image: 'assets/d2c-images/image-gen-tutorial-2.png'
    },
    {
      title: 'Step 3',
      description: 'Send me a message starting with ✍️ :\n“Show me” “I want to see” “Let me see”',
      image: 'assets/d2c-images/image-gen-tutorial-3.png'
    }
  ];

  constructor(private readonly activeModal: NgbActiveModal) {}

  nextStep(): void {
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++;
    }
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
