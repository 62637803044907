import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';

import { ICreatorsResponse } from 'app/interfaces/creator.interfaces';
import { IDiscoverItem } from '../../interfaces/discover.interfaces';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DiscoverService {
  public creators$: BehaviorSubject<IDiscoverItem[]> = new BehaviorSubject<IDiscoverItem[]>(null);
  private apiUrl = `${environment.apiBaseUrl}/discover`;

  constructor(private readonly http: HttpClient) {}

  getDiscoverData(): Observable<ICreatorsResponse> {
    return this.http.get<ICreatorsResponse>(this.apiUrl);
  }

  getDiscoverCreatorData(creatorId: string): Observable<ICreatorsResponse> {
    const endpoint = this.apiUrl + '/' + creatorId;
    return this.http.get<ICreatorsResponse>(endpoint);
  }
}
