<div class="purchase_item flex items-center space-between">
  <div class="flex items-center">
    <stxt-svg-icon
      *ngIf="purchaseType"
      [iconConfig]="purchaseSvg"
      class="purchase_item__svg"
    ></stxt-svg-icon>
    <div class="purchase_info">
      <p
        *ngIf="purchaseType"
        class="purchase_info__heading"
        [innerHTML]="purchaseType === EPurchaseType.Tokens ? 'Token package' : 'Photo'"
      ></p>
      <ng-container
        [ngTemplateOutlet]="purchaseType === EPurchaseType.Images ? imagePurchase : tokensPurchase"
      ></ng-container>
    </div>
  </div>
  <stxt-svg-icon
    [iconConfig]="rightNavSvg"
    (click)="openTransactionInfo(purchase)"
    (keydown.enter)="openTransactionInfo(purchase)"
    class="purchase_item__svg"
    tabindex="-1"
  ></stxt-svg-icon>
</div>

<ng-template #tokensPurchase>
  <p>
    <span
      [ngClass]="{
        paid: purchase.state === EPurchaseState.Paid,
        canceled: purchase.state === EPurchaseState.Canceled
      }"
      class="purchase_item__status"
    ></span>
    {{ purchase.state | titlecase }}
    <span *ngIf="purchase.state === EPurchaseState.Canceled">payment</span>
    {{ purchase.total | currency }}
    total on {{ purchase.closed_at | date: 'MM/dd/yyyy' }}
  </p>
</ng-template>

<ng-template #imagePurchase>
  <p>
    <img src="/assets/icons/token-badge.svg" alt="token" />
    {{ purchase.total | currency }}
    spent on {{ purchase.closed_at | date: 'MM/dd/yyyy' }}
  </p>
</ng-template>
