<div class="empty-state">
  <stxt-svg-icon [iconConfig]="emptyStateSvg" class="empty-state_svg"></stxt-svg-icon>
  <h4 class="empty-state_heading">{{ emptyStateData?.heading }}</h4>
  <p class="empty-state_subheading">{{ emptyStateData?.subheading }}</p>
  <stxt-button
    *ngIf="emptyStateData?.buttonText"
    class="empty-state_btn"
    [buttonConfig]="emptyStateButton"
    (click)="emptyStateButtonClicked.emit()"
    (keydown.enter)="emptyStateButtonClicked.emit()"
  >
    {{ emptyStateData?.buttonText }}
  </stxt-button>
</div>
