import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';

import { TokensService } from '../../../shared/services/tokens.service';

import { TokenStatusComponent } from 'app/shared/components/token-status/token-status.component';
import { CheckoutComponent } from 'app/components/payment/checkout/checkout.component';
import { SvgIconComponent } from '../../../shared/components/svg-icon/svg-icon.component';
import { ButtonComponent } from 'app/shared/components/button/button.component';

import { ITokenFeature, ITokenPlan } from 'app/shared/interfaces/token.interfaces';
import { IBillingInfo } from 'app/interfaces/profile.interfaces';
import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';
import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';
import { IToastConfig } from 'app/shared/interfaces/toast-config.interfaces';
import { IPaymentResult } from '../../../interfaces/payments.interfaces';

import { ETokenUsageCase } from 'app/shared/enums/tokens.enums';
import { EPaymentType } from 'app/enums/payments.enums';
import { ESvgTypes } from 'app/shared/enums/svg.enums';
import { EButtonTypes, EButtonSizes } from 'app/shared/enums/button.enums';

@Component({
  selector: 'stxt-tokens-card',
  standalone: true,
  imports: [
    CommonModule,
    TokenStatusComponent,
    CheckoutComponent,
    SvgIconComponent,
    ButtonComponent
  ],
  templateUrl: './tokens-card.component.html',
  styleUrls: ['./tokens-card.component.scss']
})
export class TokensCardComponent implements OnInit {
  @Input() billingData: IBillingInfo[] = [];
  @Input() isEditingTokens: boolean = false;
  @Input() showCheckoutComponent: boolean = false;
  @Output() showCheckoutEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('tokensStatusComponent') tokensStatusComponent: TokenStatusComponent;
  checkSvg: ISvgConfig = { name: 'done', fill: ESvgTypes.None };
  confirmBtn: IButtonConfig = {
    text: 'Next',
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Default
  };
  buyNowButton: IButtonConfig = {
    text: 'Buy now',
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Small
  };
  toast: IToastConfig = {
    toastSubheading: 'Tokens have been added to your account'
  };
  tokenFeatures: ITokenFeature[] = [
    {
      mainText: 'Custom images',
      subText: 'NSFW and X-rated'
    },
    {
      mainText: 'Fine-tune creations',
      subText: 'regenerate whenever you like'
    },
    {
      mainText: 'VIP content access',
      subText: 'adult videos, photos, and more'
    }
  ];
  selectedPlan: string = 'Popular';
  ETokenUsageCase = ETokenUsageCase;
  EPaymentType = EPaymentType;

  // TODO - tokens_pack_id to update with real data from api;
  plans: ITokenPlan[] = [
    {
      name: 'Popular',
      tokens: 100,
      save: '1%',
      price: 999,
      pricePerToken: 0.099,
      tokens_pack_id: '678edf4160fff656fb618dd0',
      quantity: 10
    },
    {
      name: 'Basic',
      tokens: 10,
      save: '1%',
      price: 199,
      pricePerToken: 0.199,
      tokens_pack_id: '678edf4160fff656fb618dd0',
      quantity: 1
    },
    {
      name: 'Starter',
      tokens: 50,
      save: '1%',
      price: 499,
      pricePerToken: 0.099,
      tokens_pack_id: '678edf4160fff656fb618dd0',
      quantity: 5
    },
    {
      name: 'Premium',
      tokens: 500,
      save: '5%',
      price: 4599,
      pricePerToken: 0.091,
      tokens_pack_id: '678edf4160fff656fb618dd0',
      quantity: 50
    }
  ];
  activePlan: ITokenPlan = this.plans[0];

  constructor(private readonly tokensService: TokensService) {}

  ngOnInit(): void {
    this.activePlan = this.plans[0];
    this.showCheckoutEvent.emit(this.showCheckoutComponent);
  }

  updateTokensScreen(paymentResult: IPaymentResult): void {
    this.tokensStatusComponent.toggleToast(paymentResult);
    this.showCheckoutComponent = false;

    if (!this.isEditingTokens) return;
    /// Timeout used to handle event after toast message is shown;
    setTimeout(() => this.tokensService.getMoreTokensEvent$.next(false), 5000);
  }

  showCheckout(): void {
    this.showCheckoutComponent = !this.showCheckoutComponent;
    this.showCheckoutEvent.emit(this.showCheckoutComponent);
  }

  confirmEditingTokens(selectedPlan: string): void {
    this.isEditingTokens = false;
    this.activePlan = this.plans.find(plan => plan.name === selectedPlan) || this.plans[0];
  }

  selectPlan(planName: string): void {
    this.selectedPlan = planName;
    this.activePlan = this.plans.find(plan => plan.name === planName) || this.plans[0];
  }
}
