<div
  [@fade]
  class="toast-message flex space-between"
  [ngClass]="{
    'toast-message_success': toast.toastType === EToastType.SUCCESS,
    'toast-message_fail': toast.toastType === EToastType.FAIL,
    'toast-message_warn': toast.toastType === EToastType.WARN
  }"
>
  <div class="flex">
    <stxt-svg-icon [iconConfig]="toastIcon" class="success-toast_check-svg"></stxt-svg-icon>
    <div class="toast-message_body">
      <p class="toast-message_heading">{{ toast?.toastHeading }}</p>
      <p *ngIf="toast.toastType !== EToastType.FAIL" class="toast-message_subheading">
        {{ toast?.toastSubheading }}
      </p>
      <p *ngIf="toast.toastType === EToastType.FAIL" class="toast-message_subheading">
        Please try again later or
        <span (click)="openSupport()" (keydown.enter)="openSupport()" tabindex="-1">
          contact support
        </span>
        .
      </p>
    </div>
  </div>
  <stxt-svg-icon
    [iconConfig]="closeIcon"
    (click)="closeToast.emit()"
    (keydown.enter)="closeToast.emit()"
    tabindex="-1"
    class="{{ toast.toastType }}-toast_close-svg"
  ></stxt-svg-icon>
</div>
