<ngx-masonry [ordered]="true">
  <ng-container *ngFor="let content of mediaContent; let i = index">
    <div
      class="masonry-item"
      ngxMasonryItem
      [ngClass]="{
        'masonry-item-video': content.type === ECreatorMediaTypes.Video,
        'masonry-item-image': content.type === ECreatorMediaTypes.Image,
        'masonry-item-text': content.type === ECreatorMediaTypes.Text
      }"
    >
      <stxt-creator-media-card
        [media]="content"
        [userId]="userId"
        [creatorId]="creator.id"
        (click)="openMediaFullScreen(content)"
        (keydown.enter)="openMediaFullScreen(content)"
      ></stxt-creator-media-card>
    </div>
  </ng-container>
</ngx-masonry>

<ng-container *ngIf="mediaFullScreen">
  <stxt-media-lightbox
    [creator]="creator"
    [mediaList]="mediaList"
    [selectedMedia]="selectedMedia"
    [usageCase]="EMediaLightboxUsage.MasonryGallery"
    (closeFullScreen)="mediaFullScreen = false"
    [postDescription]="postDescription"
  ></stxt-media-lightbox>
</ng-container>
