<ng-container *ngIf="showPaymentProcessing && paymentType !== EPaymentType.Token">
  <div class="loading_wrapper">
    <stxt-loading [loadingText]="ELoadingTexts.ProcessingPayment"></stxt-loading>
  </div>
</ng-container>
<div>
  <div *ngIf="paymentType !== EPaymentType.Token" class="checkout_info">
    <div class="checkout_info__row flex">
      <p *ngIf="paymentType === EPaymentType.Purchase">{{ creator }} AI media pack</p>
      <p *ngIf="paymentType === EPaymentType.Subscription">
        Monthly subscription - {{ creator }} AI
      </p>
    </div>
    <div class="checkout_info__row flex">
      <p>Subtotal</p>
      <p *ngIf="paymentType === EPaymentType.Purchase">
        {{ purchaseData?.price / 100 | currency }}
      </p>
      <p *ngIf="paymentType === EPaymentType.Subscription">
        {{ subscriptionData?.price / 100 | currency }}
      </p>
    </div>
    <div class="checkout_info__row flex">
      <p>Processing fees</p>
      <p>$0.00</p>
    </div>
    <div class="checkout_total flex">
      <p>Total</p>
      <p *ngIf="paymentType === EPaymentType.Purchase">
        {{ purchaseData?.price / 100 | currency }}
      </p>
      <p *ngIf="paymentType === EPaymentType.Subscription">
        {{ subscriptionData?.price / 100 | currency }}
      </p>
    </div>
  </div>

  <div *ngIf="paymentType === EPaymentType.Token" class="checkout_info">
    <div class="checkout_info__row flex">
      <div class="checkout_info_tokens">
        <div class="checkout_info_tokens__header">
          <div class="checkout_info_tokens__info flex items-center gap-2">
            <img src="assets/d2c-images/tokens-purchase.png" alt="token-1" />
            <div class="tokens-package">
              <p class="tokens-package__name">{{ tokenData?.name }} token package</p>
              <span class="tokens-package__description">
                {{ tokenData?.tokens }} tokens ({{ tokenData?.tokens | currency }} •
                {{ tokenData.pricePerToken | currency }}/token)
              </span>
            </div>
          </div>
          <stxt-svg-icon
            [iconConfig]="editTokensSvg"
            (click)="isEditingTokens.emit(true)"
            (keydown)="isEditingTokens.emit(true)"
          ></stxt-svg-icon>
        </div>
        <div class="checkout_total flex">
          <p>Total</p>
          <p>
            {{ tokenData?.price / 100 | currency }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="firstCheckOut">
    <stxt-code-verify
      *ngIf="verificationState.isVerifying"
      [email]="userProfile?.email"
      [verifiedState]="verificationState.isVerified"
      [isVerifyingState]="verificationState.isVerifying"
      (userVerified)="setUserVerified()"
    ></stxt-code-verify>

    <stxt-payment-form
      *ngIf="!billingData && verificationState.isVerified"
      [creator]="creator"
      [creatorId]="creatorId"
      [firstCheckOut]="firstCheckOut"
      [paymentType]="paymentType"
      [purchaseData]="purchaseData"
      [subscriptionData]="subscriptionData"
      [tokenData]="tokenData"
      [wsConnectionId]="wsConnectionId"
      (formValidityChange)="onFormValidityChange($event)"
      (paymentSuccessEvent)="checkPaymentResult($event)"
      (paymentFinishEvent)="showPaymentProcessing = false"
    ></stxt-payment-form>
  </ng-container>
  <ng-container *ngIf="!firstCheckOut || billingData">
    <p class="checkout_payment-method__heading">Payment method</p>
    <div *ngFor="let billingItem of billingData" class="checkout_payment-method__item">
      <div>
        <p class="checkout_payment-method__card">
          {{ billingItem.card_type }} *{{ billingItem.last_four }}
        </p>
        <p class="checkout_payment-method__address">{{ billingItem.postal_code }}</p>
      </div>
      <!--      <stxt-svg-icon [iconConfig]="arrowSvg" class="checkout_icon"></stxt-svg-icon>-->
    </div>
  </ng-container>
  <stxt-payment-failed *ngIf="isPaymentError" [error]="paymentErrorMessage"></stxt-payment-failed>
  <stxt-checkout-additional-info></stxt-checkout-additional-info>
</div>
<div class="checkout_footer">
  <form [formGroup]="termsForm">
    <div class="checkout_footer__agreement">
      <input
        class="form-check-input"
        formControlName="acceptTerms"
        id="flexCheckDefault"
        type="checkbox"
      />
      <p>
        You acknowledge that you have read and agree to
        <a target="_blank" href="{{ ETermsOfService.TermsOfUse }}" rel="noopener">
          STXT’s Terms of Use
        </a>
        .
      </p>
    </div>
  </form>
  <stxt-button
    [buttonConfig]="confirmBtn"
    [disabled]="termsForm.invalid || !verificationState.isVerified"
    (click)="confirmPayment()"
  >
    {{ confirmBtn.text }}
  </stxt-button>
  <stxt-recaptcha-terms></stxt-recaptcha-terms>
</div>
