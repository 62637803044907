import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileService } from 'app/shared/services/profile.service';

import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { IBillingHistory } from 'app/interfaces/profile.interfaces';
import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';
import { EProfileSectionTitle } from 'app/enums/profile.enums';
import { EPurchaseState, EPurchaseType } from '../../../../enums/purchases.enums';

@Component({
  selector: 'stxt-one-time-purchase-card',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './one-time-purchase-card.component.html',
  styleUrl: './one-time-purchase-card.component.scss'
})
export class OneTimePurchaseCardComponent implements OnInit {
  @Input() purchase: IBillingHistory;
  public rightNavSvg: ISvgConfig = { name: 'arrow_right', fill: ESvgTypes.None };
  public purchaseSvg: ISvgConfig = { name: 'coins-solid', fill: ESvgTypes.None };
  public selectedTransaction: IBillingHistory;
  public purchaseType: EPurchaseType;
  protected readonly EPurchaseState = EPurchaseState;
  protected readonly EPurchaseType = EPurchaseType;

  constructor(public profileService: ProfileService) {}

  ngOnInit(): void {
    this.purchaseSvg.name = this.purchaseType === EPurchaseType.Tokens ? 'coins-solid' : 'photo';
  }

  openTransactionInfo(billingItem: IBillingHistory): void {
    this.selectedTransaction = billingItem;
    this.profileService.profileActiveSection$.next(EProfileSectionTitle.View_Transaction_Info);
  }
}
