<button
  type="button"
  class="button-container w-full flex items-center justify-center pointer"
  [ngClass]="{
    dark: buttonConfig?.fill === EButtonTypes.Dark,
    empty: buttonConfig?.fill === EButtonTypes.Empty,
    filled: buttonConfig?.fill === EButtonTypes.Filled,
    colored: buttonConfig?.fill === EButtonTypes.Colored,
    'secondary-empty': buttonConfig?.fill === EButtonTypes.SecondaryEmpty,
    'secondary-filled': buttonConfig?.fill === EButtonTypes.SecondaryFilled,
    'secondary-dark': buttonConfig?.fill === EButtonTypes.SecondaryDark,
    'light-colored': buttonConfig?.fill === EButtonTypes.LightColored,
    large: buttonConfig?.buttonSize === EButtonSizes.Large,
    default: buttonConfig?.buttonSize === EButtonSizes.Default,
    small: buttonConfig?.buttonSize === EButtonSizes.Small,
    xsmall: buttonConfig?.buttonSize === EButtonSizes.XSmall
  }"
  [disabled]="disabled"
>
  <stxt-spinner *ngIf="buttonConfig?.isLoading"></stxt-spinner>
  <stxt-svg-icon
    *ngIf="buttonConfig.leadingIcon"
    [iconConfig]="iconConfig"
    class="mr-10"
  ></stxt-svg-icon>
  <ng-content></ng-content>
</button>
