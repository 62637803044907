<div class="regenerate-modal">
  <div class="regenerate-modal_wrapper">
    <stxt-svg-icon class="regenerate-icon" [iconConfig]="regenerateIcon"></stxt-svg-icon>
    <div class="regenerate-modal_title">
      <p>Regenerate Image</p>
      <span>Create a new image for 2 tokens. The current one will be saved in your images.</span>
    </div>
    <div class="regenerate-modal_footer">
      <stxt-button
        class="regenerate-button"
        [buttonConfig]="regenerateButton"
        (keydown.enter)="confirmRegenerate()"
        (click)="confirmRegenerate()"
      >
        {{ regenerateButton.text }}
      </stxt-button>
      <stxt-button
        class="cancel-button"
        [buttonConfig]="cancelButton"
        (keydown.enter)="cancel()"
        (click)="cancel()"
      >
        {{ cancelButton.text }}
      </stxt-button>
    </div>
  </div>
</div>
