import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatFeatureButtonComponent } from './chat-feature-button/chat-feature-button.component';
import { ChatFeatureOptionComponent } from './chat-feature-option/chat-feature-option.component';
import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';

import { ICreatorDiscoverData } from '../../interfaces/creator.interfaces';
import { IChatFeatureEvent } from 'app/interfaces/conversations.interfaces';
import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';

import { EChatFeatureAction, EFeatureEvent } from '../../enums/conversation.enums';
import { ESvgTypes } from '../../shared/enums/svg.enums';

@Component({
  selector: 'stxt-conversation-feature',
  standalone: true,
  imports: [CommonModule, ChatFeatureButtonComponent, ChatFeatureOptionComponent, SvgIconComponent],
  templateUrl: './conversation-feature.component.html',
  styleUrl: './conversation-feature.component.scss'
})
export class ConversationFeatureComponent {
  @Input() creatorData: ICreatorDiscoverData;
  @Input() isGeneratePhoto: boolean;
  @Output() featureEvent: EventEmitter<IChatFeatureEvent> = new EventEmitter<IChatFeatureEvent>();
  @Output() generateImage: EventEmitter<boolean> = new EventEmitter<boolean>();
  requestIcon: ISvgConfig = { name: 'photo', fill: ESvgTypes.None };
  currentMediaTagIndex: number = 0;
  isRequestPhoto: boolean = false;
  mediaTags: string[] = [];
  isLoading: boolean = false;
  protected readonly EChatFeatureAction = EChatFeatureAction;

  showFeatureBlock(): void {
    this.mediaTags = this.creatorData?.media_suggestion_tags.slice(0, 3);
    this.isRequestPhoto = true;
    this.featureEvent.emit({ eventName: EFeatureEvent.REQUEST_MEDIA_OPEN });
  }

  pickRandomTag(): string {
    const randomBuffer = new Uint32Array(1);
    window.crypto.getRandomValues(randomBuffer);
    const randomIndex = randomBuffer[0] % this.creatorData.media_suggestion_tags.length;
    return this.creatorData.media_suggestion_tags[randomIndex];
  }

  handleFeatureAction(action: string, selectedTag?: string): void {
    switch (action) {
      case EChatFeatureAction.RELOAD:
        this.reloadTags();
        break;
      case EChatFeatureAction.CLOSE:
        this.isRequestPhoto = false;
        this.featureEvent.emit({ eventName: EFeatureEvent.REQUEST_MEDIA_CLOSE });
        break;
      case EChatFeatureAction.SURPRISE_ME: {
        this.isRequestPhoto = false;
        const randomTag = this.pickRandomTag();
        this.featureEvent.emit({ eventName: EFeatureEvent.HandleSendTag, selectedTag: randomTag });
        break;
      }
      case EChatFeatureAction.SELECTED_TAG:
        this.isRequestPhoto = false;
        this.featureEvent.emit({
          eventName: EFeatureEvent.HandleSendTag,
          selectedTag: selectedTag
        });
        break;
    }
  }

  reloadTags(): void {
    this.isLoading = true;
    this.currentMediaTagIndex =
      (this.currentMediaTagIndex + 3) % this.creatorData.media_suggestion_tags.length;
    this.mediaTags = this.creatorData.media_suggestion_tags.slice(
      this.currentMediaTagIndex,
      this.currentMediaTagIndex + 3
    );

    if (this.mediaTags.length < 3) {
      const remaining = 3 - this.mediaTags.length;
      this.mediaTags = this.mediaTags.concat(
        this.creatorData.media_suggestion_tags.slice(0, remaining)
      );
    }
    setTimeout(() => (this.isLoading = false), 1000);
  }
}
