<ng-container
  [ngTemplateOutlet]="isRequestPhoto ? featureBlock : availableFeatureBlock"
></ng-container>

<ng-template #availableFeatureBlock>
  <div class="chat-feature_buttons flex">
    <div
      [ngClass]="{ 'chat-feature_active': isGeneratePhoto }"
      (click)="generateImage.emit(isGeneratePhoto)"
      (keydown.enter)="generateImage.emit(isGeneratePhoto)"
      class="chat-feature_button flex items-center"
      tabindex="0"
    >
      <stxt-svg-icon class="chat-feature_svg" [iconConfig]="requestIcon"></stxt-svg-icon>
      <p>Request photos</p>
    </div>
    <!--            <div class="chat_footer__button flex items-center">-->
    <!--              <stxt-svg-icon class="chat_footer-svg" [iconConfig]="responseIcon"></stxt-svg-icon>-->
    <!--              <p>Get responses</p>-->
    <!--            </div>-->
    <!--            <div class="chat_footer__button flex items-center">-->
    <!--              <stxt-svg-icon class="chat_footer-svg" [iconConfig]="tipIcon"></stxt-svg-icon>-->
    <!--              <p (click)="openPayment('purchase')">Tip</p>-->
    <!--            </div>-->
  </div>
</ng-template>

<ng-template #featureBlock>
  <div class="feature_block flex flex-column">
    <div class="feature_tags flex">
      <stxt-chat-feature-option
        *ngFor="let tagOption of mediaTags"
        [tagName]="tagOption"
        [isLoading]="isLoading"
        (click)="handleFeatureAction(EChatFeatureAction.SELECTED_TAG, tagOption)"
        (keydown.enter)="handleFeatureAction(EChatFeatureAction.SELECTED_TAG, tagOption)"
      ></stxt-chat-feature-option>
    </div>
    <div class="feature_buttons flex">
      <stxt-chat-feature-button
        [featureNavOption]="EChatFeatureAction.RELOAD"
        (click)="handleFeatureAction(EChatFeatureAction.RELOAD)"
        (keydown.enter)="handleFeatureAction(EChatFeatureAction.RELOAD)"
      ></stxt-chat-feature-button>
      <stxt-chat-feature-button
        [featureNavOption]="EChatFeatureAction.SURPRISE_ME"
        (click)="handleFeatureAction(EChatFeatureAction.SURPRISE_ME)"
        (keydown.enter)="handleFeatureAction(EChatFeatureAction.SURPRISE_ME)"
      ></stxt-chat-feature-button>
      <stxt-chat-feature-button
        [featureNavOption]="EChatFeatureAction.CLOSE"
        (click)="handleFeatureAction(EChatFeatureAction.CLOSE)"
        (keydown.enter)="handleFeatureAction(EChatFeatureAction.CLOSE)"
      ></stxt-chat-feature-button>
    </div>
  </div>
</ng-template>
