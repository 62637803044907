import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { ChatListService } from '../../shared/services/chat-list.service';

import { ChatItemComponent } from '../chat-item/chat-item.component';
import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';

import { IConversation } from '../../interfaces/conversations.interfaces';
import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';

import { ESvgTypes } from '../../shared/enums/svg.enums';

import { positionAnimations } from '../../shared/animations/chatListAnimation.animations';
import { fadeAnimation, fadeAnimationSequence } from '../../shared/animations/fade.animations';

@Component({
  selector: 'stxt-conversation-list',
  standalone: true,
  imports: [CommonModule, ChatItemComponent, SvgIconComponent],
  templateUrl: './conversation-list.component.html',
  styleUrl: './conversation-list.component.scss',
  animations: [positionAnimations, fadeAnimation, fadeAnimationSequence]
})
export class ConversationListComponent implements OnInit, OnDestroy {
  @Input() conversationsList: IConversation[];
  private conversationSubscription: Subscription;
  public arrowIcon: ISvgConfig = { fill: ESvgTypes.None, name: 'arrow_right2' };

  constructor(
    readonly chatListService: ChatListService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.conversationSubscription = this.chatListService.conversations$.subscribe(conversations => {
      this.conversationsList = conversations;
    });
  }

  trackById(index: number, conversation: IConversation): string {
    return `${conversation.conversation_id}-${index}`;
  }

  selectConversation(conversationId: string): void {
    this.chatListService.selectedConversationId.next(conversationId);
    this.chatListService.selectConversation(conversationId);
  }

  ngOnDestroy(): void {
    this.conversationSubscription.unsubscribe();
  }
}
