<div class="chat_feed">
  <div
    [@animatePosition]
    *ngFor="let conversation of conversationsList; trackBy: trackById"
    class="conversation-item"
  >
    <stxt-chat-item
      [conversation]="conversation"
      (click)="selectConversation(conversation.conversation_id)"
      (keydown.enter)="selectConversation(conversation.conversation_id)"
    ></stxt-chat-item>
  </div>
  <div [@fade] class="add-more">
    <p class="add-more_heading">You’ve reached the end of your conversation list</p>
    <p class="add-more_subheading flex justify-center">
      Explore more creators to chat with
      <span>
        <stxt-svg-icon
          [iconConfig]="arrowIcon"
          class="add-more_svg"
          (click)="router.navigateByUrl('')"
          (keydown.enter)="router.navigateByUrl('')"
          tabindex="-1"
        ></stxt-svg-icon>
      </span>
    </p>
  </div>
</div>
