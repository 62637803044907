import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { CommonModule } from '@angular/common';

import { first } from 'rxjs';

import { AuthService } from '../../../auth/auth.service';
import { EmailService } from '../../../shared/services/email.service';
import { AnalyticsService } from 'app/shared/services/analytics.service';

import { ButtonComponent } from '../../../shared/components/button/button.component';
import { InputComponent } from '../../../shared/components/input/input.component';
import { ResendCodeComponent } from '../../../shared/components/resend-code/resend-code.component';
import { ToastComponent } from '../../../shared/components/toast/toast.component';

import { IInputCustomConfig } from '../../../shared/interfaces/input.interfaces';
import { IButtonConfig } from '../../../shared/interfaces/button.interfaces';
import { IUserAttributeVerify } from '../../../auth/auth.interfaces';
import { IToastConfig } from '../../../shared/interfaces/toast-config.interfaces';

import {
  EInputCustomType,
  EInputSupportMessage,
  EInputUsage
} from '../../../shared/enums/input.enums';
import { EButtonSizes, EButtonTypes } from '../../../shared/enums/button.enums';
import { ELocalStorageKey, EResendCodeUsage } from '../../../shared/enums/shared.enums';
import { EVerifyAttributeName } from '../../../auth/auth.enums';
import { EProfileState } from 'app/enums/profile.enums';
import { EToastType } from 'app/shared/enums/toast.enums';

@Component({
  selector: 'stxt-verify-email',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    InputComponent,
    ReactiveFormsModule,
    ResendCodeComponent,
    ToastComponent
  ],
  templateUrl: './verify-email.component.html',
  styleUrl: './verify-email.component.scss'
})
export class VerifyEmailComponent implements OnInit {
  @Input() email: string;
  @Output() emailVerified: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('resendCode') resendCode!: ResendCodeComponent;
  inputCodeLength: number = 6;
  verifyForm: FormGroup;
  verifyCodeInput: IInputCustomConfig = {
    placeholder: '6-digit code',
    supportingText: {
      text: EInputSupportMessage.VerifyCodeRequired
    },
    type: EInputCustomType.Number,
    usageCase: EInputUsage.Page
  };
  verifyButton: IButtonConfig = {
    fill: EButtonTypes.SecondaryFilled,
    text: 'Verify',
    buttonSize: EButtonSizes.Default
  };
  toast: IToastConfig = {
    toastHeading: 'Security code was resent',
    toastSubheading: 'Please check your email for another 6-digit code.',
    toastType: EToastType.SUCCESS
  };
  isToastMessage: boolean = false;
  codeError: boolean = false;
  cognitoAccessToken = localStorage.getItem(ELocalStorageKey.CognitoAccessToken);
  userId: string = localStorage.getItem(ELocalStorageKey.UserId);
  protected readonly EResendCodeUsage = EResendCodeUsage;

  constructor(
    public authService: AuthService,
    public emailService: EmailService,
    public formBuilder: FormBuilder,
    public analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.initVerifyForm();
    this.email = this.emailService.modifyEmail(this.email);
  }

  initVerifyForm(): void {
    this.verifyForm = this.formBuilder.group({
      verifyCode: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[0-9]/),
          Validators.minLength(this.inputCodeLength),
          Validators.maxLength(this.inputCodeLength),
          this.invalidCodeValidator
        ]
      ]
    });
  }

  verifyEmail(): void {
    if (!this.verifyForm.valid) return;
    const verifyCode = this.verifyForm.value.verifyCode;
    const verifyData: IUserAttributeVerify = {
      attribute_name: EVerifyAttributeName.EMAIL,
      cognito_access_token: this.cognitoAccessToken,
      verification_code: verifyCode
    };
    this.authService
      .verifyCode(verifyData)
      .pipe(first())
      .subscribe({
        next: () => {
          this.emailVerified.emit();
          localStorage.removeItem(ELocalStorageKey.VerifySent);
          this.trackEmailVerified();
        },
        error: err => {
          if (err.status === 200) {
            this.emailVerified.emit();
            localStorage.removeItem(ELocalStorageKey.VerifySent);
            this.trackEmailVerified();
          } else {
            console.error(err);
            this.handleVerificationError();
          }
        }
      });
  }

  trackEmailVerified(): void {
    this.analyticsService.onboardingProfile(
      EProfileState.PROFILE_EMAIL_VERIFICATION,
      this.userId,
      null,
      null
    );
  }

  handleVerificationError(): void {
    this.codeError = true;
    this.verifyCodeInput.supportingText.text = EInputSupportMessage.VerifyCodeError;

    const verifyCodeControl = this.verifyForm.get('verifyCode');
    verifyCodeControl.addValidators(this.invalidCodeValidator);
    verifyCodeControl.updateValueAndValidity({ emitEvent: true });
    this.resendCode.sendVerifyCode(true);

    verifyCodeControl.valueChanges.pipe(first()).subscribe(() => {
      this.codeError = false;
      verifyCodeControl.removeValidators(this.invalidCodeValidator);
      verifyCodeControl.updateValueAndValidity();
      this.verifyCodeInput.supportingText.text = EInputSupportMessage.VerifyCodeRequired;
    });
  }

  invalidCodeValidator: ValidatorFn = () => {
    return this.codeError ? { invalidCode: true } : null;
  };
}
