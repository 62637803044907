<stxt-header *ngIf="!isLander"></stxt-header>
<div class="app_content flex items-center justify-center">
  <router-outlet></router-outlet>
  <ng-container *ngIf="isLander">
    <stxt-auth-form (closeAuth)="isLander = false" formType="Lander"></stxt-auth-form>
  </ng-container>
</div>
<ng-container *ngIf="showFooter">
  <stxt-footer></stxt-footer>
</ng-container>
