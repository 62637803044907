import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Subject, takeUntil } from 'rxjs';

import { ISvgConfig } from '../../interfaces/svg.interfaces';
import { ESvgTypes } from '../../enums/svg.enums';

@Component({
  selector: 'stxt-svg-icon',
  standalone: true,
  imports: [CommonModule, HttpClientModule],
  templateUrl: './svg-icon.component.html',
  styleUrl: './svg-icon.component.scss'
})
export class SvgIconComponent implements OnChanges, OnDestroy {
  @Input() public iconConfig: ISvgConfig;
  @Input() public disabled?: boolean;
  public readonly ESvgTypes = ESvgTypes;
  public svgIcon: SafeHtml | undefined;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['iconConfig']) {
      this.getSvgHtml();
    }
  }

  getSvgHtml(): void {
    if (!this.iconConfig.name) return;
    this.httpClient
      .get(`assets/icons/${this.iconConfig.name}.svg`, { responseType: 'text' })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: value => {
          this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
        },
        error: () => {
          console.warn(`Could not load SVG: assets/icons/${this.iconConfig.name}.svg`);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
