<div
  class="creator-card"
  [@fade]
  [ngClass]="{ 'selected-creator': selectedCreator.id === creatorItem.id }"
>
  <div class="image_wrapper">
    <img
      class="primary-image"
      [ngSrc]="creatorItem?.image"
      priority
      fill
      [alt]="creatorItem.name"
    />
    <img
      class="secondary-image"
      [ngSrc]="
        creatorItem?.secondary_image !== '' ? creatorItem?.secondary_image : creatorItem?.image
      "
      [alt]="creatorItem.secondary_image"
      fill
    />
  </div>
  <div class="creator-card_text">
    <div class="creator-card_name relative">
      {{ creatorItem.name }}
      <span class="creator-card_name__badge absolute">
        <stxt-svg-icon class="verify-svg" [iconConfig]="verifySvg"></stxt-svg-icon>
      </span>
    </div>
  </div>
</div>
