<div class="image-presets">
  <div class="image-presets_tabs">
    <button
      *ngFor="let preset of presetList"
      class="image-presets_tabs__chip btn-nostyle"
      (click)="onPresetClick(preset)"
      (keydown.enter)="onPresetClick(preset)"
      [ngClass]="{ selected: selectedPreset === preset }"
    >
      <p>{{ preset }}</p>
    </button>
  </div>
  <div class="image-presets_content">
    <button
      *ngFor="let image of images[selectedPreset]"
      (keydown.enter)="onImageSelect(image.image)"
      (click)="onImageSelect(image.image)"
      class="images-wrapper btn-nostyle"
    >
      <div
        class="image-overlay"
        [ngClass]="{
          image_selected: selectedImages.has(image.image)
        }"
      >
        <img class="image-presets_content__image" [src]="image.image" alt="Preset" />
      </div>
      <p class="image-presets_content__label">{{ image?.label }}</p>
      <div
        class="add-icon"
        [ngClass]="{
          icon_visible: !selectedImages.has(image.image),
          icon_hidden: selectedImages.has(image.image)
        }"
      >
        <stxt-svg-icon [iconConfig]="addIcon" class="image-presets_svg"></stxt-svg-icon>
      </div>
      <div
        class="done-icon"
        [ngClass]="{
          icon_visible: selectedImages.has(image.image),
          icon_hidden: !selectedImages.has(image.image)
        }"
      >
        <stxt-svg-icon [iconConfig]="doneIcon" class="image-presets_svg"></stxt-svg-icon>
      </div>
    </button>
  </div>
</div>
