<div class="subscription-card">
  <div class="subscription-card_image">
    <img
      *ngIf="subscriptionItem?.creator?.image"
      [src]="subscriptionItem?.creator?.image"
      alt="{{ subscriptionItem?.creator?.image }}"
    />
  </div>
  <div class="subscription-card_content">
    <span class="subscription-card_content__header">
      {{ subscriptionItem?.creator.name }}
    </span>
    <div class="card-expiry">
      <span
        [ngClass]="{
          active: subscriptionItem.state === SubscriptionState.Active,
          soon: subscriptionItem.state === SubscriptionState.Soon,
          expired: subscriptionItem.state === SubscriptionState.Expired,
          default: subscriptionItem.state === undefined || subscriptionItem.state === null
        }"
        class="card-expiry_status"
      ></span>
      <div [ngSwitch]="subscriptionItem.state">
        <span *ngSwitchCase="SubscriptionState.Active" class="card-expiry_text">
          Renews {{ subscriptionItem.current_period_ends_at | date: 'MM/dd/yyyy' }}
        </span>
        <span *ngSwitchCase="SubscriptionState.Soon" class="card-expiry_text">
          Expiring on {{ subscriptionItem.current_period_ends_at | date: 'MM/dd/yyyy' }}
        </span>
        <span *ngSwitchCase="SubscriptionState.Expired" class="card-expiry_text">
          Expired on {{ subscriptionItem.current_period_ends_at | date: 'MM/dd/yyyy' }}
        </span>
      </div>
    </div>
  </div>
  <stxt-svg-icon [iconConfig]="arrowRightIcon" class="subscription-card_arrow-icon"></stxt-svg-icon>
</div>
