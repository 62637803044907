import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { AuthService } from '../../auth/auth.service';
import { AnalyticsService } from 'app/shared/services/analytics.service';
import { ChatListService } from 'app/shared/services/chat-list.service';
import { TokensService } from 'app/shared/services/tokens.service';

import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';
import { AuthFormComponent } from '../../shared/components/auth-form/auth-form.component';
import { ProfileFlyoutComponent } from '../profile-flyout/profile-flyout.component';
import { NotificationBadgeComponent } from '../../shared/components/notification-badge/notification-badge.component';
import { TokenStatusComponent } from '../../shared/components/token-status/token-status.component';

import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';

import { ESvgTypes } from '../../shared/enums/svg.enums';
import { EButtonActionTypes } from 'app/shared/enums/button.enums';
import { EAuthType } from 'app/auth/auth.enums';
import { ELocalStorageKey } from 'app/shared/enums/shared.enums';
import { EProfileState, EProfileTab } from 'app/enums/profile.enums';
import { ETokenType, ETokenUsageCase } from 'app/shared/enums/tokens.enums';

@Component({
  selector: 'stxt-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SvgIconComponent,
    AuthFormComponent,
    ProfileFlyoutComponent,
    NotificationBadgeComponent,
    TokenStatusComponent
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  public profileSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'profile' };
  public chatSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'conversation' };
  public generateSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'generate-image' };
  public homeSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'home' };
  public openProfileIntent: EProfileTab = EProfileTab.Account;
  public getTokensScreen: boolean = false;
  EProfileTab = EProfileTab;
  $isUser = this.authService.$isUser;
  ETokenUsageCase = ETokenUsageCase;
  rightIcon: ISvgConfig = { name: 'arrow_right2', fill: ESvgTypes.None };
  showProfileFlyout: boolean;
  showDropdown: boolean = false;
  user = JSON.parse(localStorage.getItem(ELocalStorageKey.User));
  userId: string = localStorage.getItem(ELocalStorageKey.UserId);
  pageUrl: string;
  routerSubscription: Subscription;
  tokensBalanceEvent$: Subscription;
  tokenType: ETokenType;
  ETokenType = ETokenType;

  constructor(
    public modal: NgbModal,
    public router: Router,
    public authService: AuthService,
    public analyticsService: AnalyticsService,
    public chatListService: ChatListService,
    private readonly tokensService: TokensService
  ) {}

  ngOnInit(): void {
    this.isUser();
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.pageUrl = event.urlAfterRedirects;
      }
    });
    this.tokensService.fetchTokensBalance();
    this.trackTokenBalanceEvent();
  }

  trackTokenBalanceEvent(): void {
    this.tokensBalanceEvent$ = this.tokensService.getMoreTokensEvent$.subscribe((res: boolean) => {
      this.showProfileFlyout = res;
      this.showDropdown = res;
      this.getTokensScreen = res;

      if (!res) return;

      this.openProfileIntent = EProfileTab.Wallet;
    });
  }

  async isUser(): Promise<void> {
    await this.authService.currentSession();
  }

  openAuth(authType: string, clickText: string): void {
    let modalRef;
    if (window.innerWidth < 640) {
      modalRef = this.modal.open(AuthFormComponent, {
        size: 'sm',
        windowClass: 'modal-fullscreen'
      });
    } else {
      modalRef = this.modal.open(AuthFormComponent);
    }
    modalRef.componentInstance.isSignUp = authType === EAuthType.SignUp;
    let authButtonType;
    const pageName = this.getPageName(this.pageUrl);
    authType === EAuthType.Login
      ? (authButtonType = EButtonActionTypes.TriggerLogin)
      : (authButtonType = EButtonActionTypes.TriggerSignUp);
    this.analyticsService.click(clickText, '', pageName, authButtonType, '');
  }

  trackCTAEvent(clickText: string, url: string): void {
    const pageName = this.getPageName(this.pageUrl);
    this.analyticsService.click(clickText, url, pageName, EButtonActionTypes.HeaderNavigation, url);
  }

  getPageName(url: string): string {
    if (!url) {
      return 'Home';
    }
    // Implement logic to derive page name from URL
    const segments = url.split('/');
    return segments[segments.length - 1] || 'Home';
  }

  openProfile(event?: string): void {
    if (event === 'user-updated') {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.showDropdown = false;
    } else if (event === EProfileState.PROFILE_COMPLETE) {
      this.showProfileFlyout = true;
      this.showDropdown = !this.showDropdown;
      localStorage.setItem(ELocalStorageKey.ProfileCompleted, 'true');
      localStorage.removeItem(ELocalStorageKey.ProfileOpened);
    } else {
      this.showProfileFlyout = true;
      this.showDropdown = !this.showDropdown;
    }
  }

  openChat(): void {
    const currentUrl = this.router.url;
    const isMobile = window.innerWidth <= 640;
    const isInChat = currentUrl.includes('/chat');

    if (isInChat && isMobile) {
      this.chatListService.isChatsFullScreen.next(true);
      return;
    }

    if (!isInChat) {
      this.trackCTAEvent('Chat', '/chat');
      this.router.navigateByUrl('/chat').then(() => {
        if (!isMobile) return;
        this.chatListService.isChatsFullScreen.next(true);
      });
    }
  }

  setProfileIntent(profileIntent: EProfileTab, event?: Event): void {
    if (!event) return;
    event.stopPropagation();
    if (this.showDropdown && this.openProfileIntent === profileIntent) return;
    this.openProfileIntent = profileIntent;
    this.openProfile();
  }

  getTokenType(tokenType: ETokenType): void {
    this.tokenType = tokenType;
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.tokensBalanceEvent$.unsubscribe();
  }
}
