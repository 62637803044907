<div class="step">
  <div class="step_title">
    {{ steps[currentStep].title }}
  </div>
  <div class="step_description">
    {{ steps[currentStep].description }}
  </div>
  <div class="step_image">
    <img
      [src]="steps[currentStep].image"
      alt="Tutorial step visualization"
      aria-label="Tutorial step visualization"
    />
  </div>
  <div class="step_footer">
    <div class="step_footer__text">{{ currentStep + 1 }} of {{ steps.length }}</div>
    <div class="step_footer__button">
      <button *ngIf="currentStep <= 1" (click)="nextStep()" class="btn-nostyle">
        <stxt-svg-icon [iconConfig]="nextIcon"></stxt-svg-icon>
      </button>
      <stxt-button
        *ngIf="currentStep === 2"
        [buttonConfig]="closeButton"
        (click)="closeModal()"
        (keyup)="closeModal()"
        class="close-button"
      >
        <span>Got it</span>
        <stxt-svg-icon [iconConfig]="closeIcon"></stxt-svg-icon>
      </stxt-button>
    </div>
  </div>
</div>
