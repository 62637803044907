<ng-container
  *ngIf="(profileSettingsTitle$ | async) === EProfileSectionTitle.View_Subscription_Info"
>
  <div class="subscription-management">
    <div
      class="subscription-management_head flex flex-column"
      [ngStyle]="{
        background:
          'linear-gradient(180deg, rgba(26, 20, 26, 0.24) 0.01%, #1A141A 75.65%), url(' +
          selectedSubscription.creator.image +
          ') lightgray 50% / cover no-repeat'
      }"
    >
      <p class="subscription-management_head__name">{{ selectedSubscription.creator.name }}</p>
      <div class="subscription-management_head__info flex">
        <div class="flex flex-column">
          <p class="subscription-management_item__value">
            <span
              [ngClass]="{
                active: selectedSubscription.state === SubscriptionState.Active,
                soon: selectedSubscription.state === SubscriptionState.Soon,
                expired: selectedSubscription.state === SubscriptionState.Expired
              }"
              class="subscription_status"
            ></span>
            {{ selectedSubscription.state | titlecase }}
          </p>
          <p class="subscription-management_item__parameter">Status</p>
        </div>
        <div class="flex flex-column">
          <p class="subscription-management_item__value">
            {{ selectedSubscription.created_at | date: 'MMM dd' }}
          </p>
          <p class="subscription-management_item__parameter">Since</p>
        </div>
        <div class="flex flex-column">
          <p class="subscription-management_item__value">
            {{ selectedSubscription.current_period_ends_at | date: 'MMM dd' }}
          </p>
          <p class="subscription-management_item__parameter">Renews on</p>
        </div>
      </div>
    </div>
    <div class="subscription-management_body">
      <p class="subscription-management_body__heading">Subscription settings</p>
      <div class="auto-renewal flex">
        <div class="auto-renewal_main">
          <p class="auto-renewal_main__heading">Auto-renewal</p>
          <p class="auto-renewal_main__paragraph mb-4">
            {{ selectedSubscription.creator.sub_plan.price }}
            tokens will be depleted per month when auto-renewal is on.
          </p>
          <p class="auto-renewal_main__paragraph">
            Turn off auto-renewal at least a day before
            {{ selectedSubscription.current_period_ends_at | date: 'MM/dd/yyyy' }}.
          </p>
        </div>
        <div class="toggle-wrapper relative">
          <stxt-switch
            [(isOn)]="selectedSubscription.auto_renew"
            (stateChange)="onStateChange($event)"
          ></stxt-switch>
        </div>
      </div>
      <!--hidden due to ticket GFAI-1664 requirements-->
      <!--      <div *ngIf="transactionsLoaded">-->
      <!--        <div class="billing-history flex space-between items-center">-->
      <!--          <div>-->
      <!--            <p class="billing-history_name">Billing history</p>-->
      <!--            <p class="billing-history_value">-->
      <!--              Most recent: {{ transactionsHistory[0].state | titlecase }}-->
      <!--              {{ transactionsHistory[0].total | currency }} total on-->
      <!--              {{ transactionsHistory[0].closed_at | date: 'MM/dd/yyyy' }}-->
      <!--            </p>-->
      <!--          </div>-->
      <!--          <stxt-svg-icon-->
      <!--            [iconConfig]="rightNavSvg"-->
      <!--            (click)="openBillingHistory()"-->
      <!--            (keydown.enter)="openBillingHistory()"-->
      <!--            class="billing-history_item__svg"-->
      <!--          ></stxt-svg-icon>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</ng-container>
<ng-container
  *ngIf="
    (profileSettingsTitle$ | async) === EProfileSectionTitle.View_Billing_History ||
    EProfileSectionTitle.View_Transaction_Info
  "
>
  <stxt-billing-history
    [billingHistoryItems]="transactionsHistory"
    [creator]="selectedSubscription.creator.name"
  ></stxt-billing-history>
</ng-container>
