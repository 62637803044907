import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreatorCardComponent } from '../creator-card/creator-card.component';
import { SvgIconComponent } from '../../../shared/components/svg-icon/svg-icon.component';

import { IDiscoverItem } from '../../../interfaces/discover.interfaces';
import { ISvgConfig } from '../../../shared/interfaces/svg.interfaces';

import { ESvgTypes } from '../../../shared/enums/svg.enums';

@Component({
  selector: 'stxt-creators-carousel',
  standalone: true,
  imports: [CommonModule, CreatorCardComponent, SvgIconComponent],
  templateUrl: './creators-carousel.component.html',
  styleUrls: ['./creators-carousel.component.scss']
})
export class CreatorsCarouselComponent implements AfterViewInit {
  @Input() creators: IDiscoverItem[];
  @Input() selectedCreator: IDiscoverItem;
  @Output() selectCreatorEvent: EventEmitter<IDiscoverItem> = new EventEmitter<IDiscoverItem>();
  @ViewChild('carousel') carousel: ElementRef;
  canScrollPrev: boolean = false;
  canScrollNext: boolean = false;
  public leftNavigation: ISvgConfig = { fill: ESvgTypes.Tonal, name: 'arrow_left' };
  public rightNavigation: ISvgConfig = { fill: ESvgTypes.Tonal, name: 'arrow_right' };

  ngAfterViewInit(): void {
    this.updateScrollButtons();
  }

  selectCreator(creator: IDiscoverItem): void {
    this.selectedCreator = creator;
    this.selectCreatorEvent.emit(creator);
  }

  scroll(direction: number): void {
    const container = this.carousel?.nativeElement;
    const itemWidth = container.children[0].offsetWidth + 8;
    container.scrollBy({ left: direction * itemWidth, behavior: 'smooth' });
  }

  onScroll(): void {
    this.updateScrollButtons();
  }

  updateScrollButtons(): void {
    const container = this.carousel?.nativeElement;
    let contentWidth: number = 0;
    for (const child of container.children) {
      const childElement = child as HTMLElement;
      contentWidth += childElement.offsetWidth;
    }

    this.canScrollPrev = container?.scrollLeft > 0;
    if (container.scrollWidth === 0) return;
    this.canScrollNext = container?.scrollLeft + container?.offsetWidth < contentWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.updateScrollButtons();
  }
}
