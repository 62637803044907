import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';
import { IPresetImages } from 'app/interfaces/image-generation.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';

@Component({
  selector: 'stxt-image-presets',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './image-presets.component.html',
  styleUrl: './image-presets.component.scss'
})
export class ImagePresetsComponent {
  presetList: string[] = ['Scene', 'Activity', 'Outfit', 'Accessories', 'Pose'];
  selectedPreset: string = 'Scene';
  addIcon: ISvgConfig = { fill: ESvgTypes.Filled, name: 'add' };
  doneIcon: ISvgConfig = { fill: ESvgTypes.Filled, name: 'done' };
  selectedImages: Set<string> = new Set();
  images: IPresetImages = {
    Scene: [
      { label: 'Sunset Beach', image: 'https://picsum.photos/seed/sunset-beach/300/400' },
      { label: 'City Night', image: 'https://picsum.photos/seed/city-night/300/400' },
      { label: 'Tropical', image: 'https://picsum.photos/seed/tropical/300/400' },
      { label: 'Snow', image: 'https://picsum.photos/seed/snow-peak/300/400' },
      { label: 'Misty', image: 'https://picsum.photos/seed/misty-woods/300/400' },
      { label: 'Desert Dunes', image: 'https://picsum.photos/seed/desert/300/400' },
      { label: 'Modern City', image: 'https://picsum.photos/seed/modern-city/300/400' },
      { label: 'Lake View', image: 'https://picsum.photos/seed/lake/300/400' },
      { label: 'Valley Hills', image: 'https://picsum.photos/seed/valley/300/400' },
      { label: 'Garden Park', image: 'https://picsum.photos/seed/garden/300/400' }
    ],
    Activity: [
      { label: 'Morning Jog', image: 'https://picsum.photos/seed/morning-jog/300/400' },
      { label: 'Beach', image: 'https://picsum.photos/seed/beach-volley/300/400' },
      { label: 'Yoga Session', image: 'https://picsum.photos/seed/yoga-pose/300/400' },
      { label: 'Mountain Biking', image: 'https://picsum.photos/seed/bike-trail/300/400' },
      { label: 'Dance Studio', image: 'https://picsum.photos/seed/dance-moves/300/400' },
      { label: 'Swimming Laps', image: 'https://picsum.photos/seed/pool-swim/300/400' },
      { label: 'Rock Climbing', image: 'https://picsum.photos/seed/rock-climb/300/400' },
      { label: 'Tennis Match', image: 'https://picsum.photos/seed/tennis-court/300/400' },
      { label: 'Pilates Class', image: 'https://picsum.photos/seed/pilates-mat/300/400' },
      { label: 'Surfing Waves', image: 'https://picsum.photos/seed/surf-beach/300/400' },
      { label: 'Boxing Training', image: 'https://picsum.photos/seed/boxing-ring/300/400' },
      { label: 'Golf Swing', image: 'https://picsum.photos/seed/golf-green/300/400' }
    ],
    Outfit: [
      { label: 'Summer Dress', image: 'https://picsum.photos/seed/summer-style/300/400' },
      { label: 'Business Suit', image: 'https://picsum.photos/seed/office-wear/300/400' },
      { label: 'Workout Gear', image: 'https://picsum.photos/seed/gym-outfit/300/400' },
      { label: 'Cocktail Dress', image: 'https://picsum.photos/seed/evening-gown/300/400' },
      { label: 'Boho Style', image: 'https://picsum.photos/seed/boho-fashion/300/400' },
      { label: 'Winter Coat', image: 'https://picsum.photos/seed/winter-style/300/400' },
      { label: 'Casual Jeans', image: 'https://picsum.photos/seed/denim-look/300/400' },
      { label: 'Party Dress', image: 'https://picsum.photos/seed/party-glam/300/400' },
      { label: 'Beach Wear', image: 'https://picsum.photos/seed/beach-style/300/400' },
      { label: 'Leather Jacket', image: 'https://picsum.photos/seed/leather-cool/300/400' },
      { label: 'Athleisure', image: 'https://picsum.photos/seed/sport-casual/300/400' },
      { label: 'Vintage Look', image: 'https://picsum.photos/seed/retro-style/300/400' }
    ],
    Accessories: [
      { label: 'Statement Necklace', image: 'https://picsum.photos/seed/necklace-gold/300/400' },
      { label: 'Designer Handbag', image: 'https://picsum.photos/seed/luxury-bag/300/400' },
      { label: 'Sunglasses Collection', image: 'https://picsum.photos/seed/summer-shades/300/400' },
      { label: 'Vintage Watch', image: 'https://picsum.photos/seed/classic-watch/300/400' },
      { label: 'Hair Accessories', image: 'https://picsum.photos/seed/hair-style/300/400' },
      { label: 'Statement', image: 'https://picsum.photos/seed/earring-glam/300/400' },
      { label: 'Luxury Belt', image: 'https://picsum.photos/seed/belt-fashion/300/400' },
      { label: 'Designer Scarf', image: 'https://picsum.photos/seed/scarf-style/300/400' },
      { label: 'Ankle Boots', image: 'https://picsum.photos/seed/boots-trend/300/400' },
      { label: 'Cocktail Ring', image: 'https://picsum.photos/seed/ring-sparkle/300/400' },
      { label: 'Charm Bracelet', image: 'https://picsum.photos/seed/bracelet-charm/300/400' },
      { label: 'Designer Wallet', image: 'https://picsum.photos/seed/wallet-lux/300/400' }
    ],
    Pose: [
      { label: 'Portrait Pose', image: 'https://picsum.photos/seed/portrait-style/300/400' },
      { label: 'Casual Stance', image: 'https://picsum.photos/seed/natural-pose/300/400' },
      { label: 'Action Shot', image: 'https://picsum.photos/seed/dynamic-pose/300/400' },
      { label: 'Sitting Pose', image: 'https://picsum.photos/seed/seated-style/300/400' },
      { label: 'Profile View', image: 'https://picsum.photos/seed/side-pose/300/400' },
      { label: 'Walking Pose', image: 'https://picsum.photos/seed/walk-style/300/400' },
      { label: 'Candid Laugh', image: 'https://picsum.photos/seed/laugh-natural/300/400' },
      { label: 'Fashion Stance', image: 'https://picsum.photos/seed/fashion-pose/300/400' },
      { label: 'Dramatic Look', image: 'https://picsum.photos/seed/dramatic-shot/300/400' },
      { label: 'Elegant Pose', image: 'https://picsum.photos/seed/elegant-style/300/400' },
      { label: 'Dance Move', image: 'https://picsum.photos/seed/dance-pose/300/400' },
      { label: 'Lifestyle Shot', image: 'https://picsum.photos/seed/lifestyle-candid/300/400' }
    ]
  };

  onPresetClick(preset: string): void {
    this.selectedPreset = preset;
  }

  onImageSelect(imageUrl: string): void {
    if (this.selectedImages.has(imageUrl)) {
      this.selectedImages.delete(imageUrl);
    } else {
      this.selectedImages.add(imageUrl);
    }
  }
}
