<div
  class="feature-tag"
  [ngClass]="{
    chat_prompt: usageCase === EPromptUsage.Chat,
    'generate-image_prompt': usageCase === EPromptUsage.ImageGeneration
  }"
>
  <p class="feature-tag_name" [innerHTML]="highlightedPrompt"></p>
  <stxt-svg-icon
    *ngIf="usageCase === EPromptUsage.Chat"
    class="feature-tag_icon"
    [iconConfig]="sendIcon"
  ></stxt-svg-icon>
</div>
