import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivateFn,
  Router
} from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { NavigationIntentService } from 'app/shared/services/navigation-intent.service';
import { AuthService } from './auth.service';

import { AuthFormComponent } from 'app/shared/components/auth-form/auth-form.component';

const checkAgeVerification = (
  navigationIntentService: NavigationIntentService,
  intendedUrl: string
): boolean => {
  const isAgeVerified = localStorage.getItem('ageVerified') === 'true';
  if (!isAgeVerified) {
    navigationIntentService.setIntent(intendedUrl);
  }
  return isAgeVerified;
};

const openAuthModal = (modalService: NgbModal, isClosable = true) => {
  const modalRef = modalService.open(AuthFormComponent, { backdrop: 'static', keyboard: false });
  modalRef.componentInstance.isSignUp = true;
  if (!isClosable) {
    modalRef.componentInstance.closeAndNavigate = true;
  }
  return modalRef;
};

const handleNonAuthenticatedUser = (
  state: string,
  navigationIntentService: NavigationIntentService,
  modalService: NgbModal,
  authService: AuthService,
  isChatRoute: boolean,
  isGenerateRoute: boolean,
  router: Router
): Observable<boolean | UrlTree> => {
  navigationIntentService.setIntent(state);

  if (isChatRoute || isGenerateRoute) {
    setTimeout(() => {
      const modalRef = openAuthModal(modalService, false);
      modalRef.closed
        .pipe(switchMap(() => authService.$isUser.pipe(take(1))))
        .subscribe(isAuthenticated => {
          if (!isAuthenticated) return;
          navigationIntentService.navigateToIntentOrDefault();
        });
    });
    return of(router.createUrlTree(['/']));
  }

  setTimeout(() => {
    const modalRef = openAuthModal(modalService);
    modalRef.closed
      .pipe(switchMap(() => authService.$isUser.pipe(take(1))))
      .subscribe(isAuthenticated => {
        if (!isAuthenticated) return;
        navigationIntentService.navigateToIntentOrDefault();
      });
  });

  return of(router.createUrlTree(['/']));
};

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> => {
  const authService = inject(AuthService);
  const modalService = inject(NgbModal);
  const navigationIntentService = inject(NavigationIntentService);
  const router = inject(Router);

  if (!checkAgeVerification(navigationIntentService, state.url)) {
    return of(router.createUrlTree(['/']));
  }

  const isChatRoute = state.url.startsWith('/chat/');
  const isGenerateRoute = state.url.startsWith('/generate');

  return from(authService.currentSession()).pipe(
    switchMap(() => authService.$isUser.pipe(take(1))),
    switchMap(isUser => {
      if (!isUser) {
        return handleNonAuthenticatedUser(
          state.url,
          navigationIntentService,
          modalService,
          authService,
          isChatRoute,
          isGenerateRoute,
          router
        );
      }
      return of(true);
    })
  );
};
