export enum EToastUsage {
  RESEND = 'resend',
  VERIFIED = 'verified',
  IMAGE_GENERATED = 'image_generated',
  IMAGE_GENERATING = 'image_generating',
  IMAGE_GENERATION_FAILED = 'image_generation_failed'
}

export enum EToastType {
  SUCCESS = 'success',
  FAIL = 'fail',
  WARN = 'warn'
}
