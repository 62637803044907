import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { SpinnerComponent } from '../spinner/spinner.component';

import { IButtonConfig } from '../../interfaces/button.interfaces';
import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';

import { EButtonSizes, EButtonTypes } from '../../enums/button.enums';
import { ESvgTypes } from 'app/shared/enums/svg.enums';

@Component({
  selector: 'stxt-button',
  standalone: true,
  imports: [SvgIconComponent, CommonModule, SpinnerComponent],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Input() buttonConfig?: IButtonConfig;
  @Input() disabled?: boolean;
  public readonly EButtonTypes = EButtonTypes;
  public readonly EButtonSizes = EButtonSizes;

  get iconConfig(): ISvgConfig {
    return { name: this.buttonConfig?.leadingIcon, fill: ESvgTypes.Filled };
  }
}
