import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
  Event,
  ActivatedRoute
} from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { fetchAuthSession } from 'aws-amplify/auth';
import { filter } from 'rxjs/operators';

import { AuthService } from './auth/auth.service';
import { AnalyticsService } from './shared/services/analytics.service';

import { HeaderComponent } from './components/header/header.component';
import { AuthFormComponent } from './shared/components/auth-form/auth-form.component';
import { FooterComponent } from './components/footer/footer.component';
import { AgeVerificationComponent } from './components/age-verification/age-verification.component';

import { environment } from 'environments/environment';

@Component({
  selector: 'stxt-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterModule,
    HeaderComponent,
    AuthFormComponent,
    FooterComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title: string = 'stxt-fe-main';
  isLander: boolean = false;
  showFooter: boolean = false;
  ageVerified = localStorage.getItem('ageVerified');
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  constructor(
    public authService: AuthService,
    readonly modal: NgbModal,
    readonly route: ActivatedRoute,
    readonly analyticsService: AnalyticsService,
    readonly router: Router,
    readonly meta: Meta
  ) {
    this.router.events
      .pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.showFooter = this.checkScreenType(event.url);
        this.isLanderScreen();
      });
  }

  checkScreenType(url: string): boolean {
    return !url.includes('/generate') && !url.includes('/chat');
  }

  ngOnInit(): void {
    this.checkAgeVerification();
    this.authService.checkUserCredentials();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.trackPage(event.urlAfterRedirects);
      }
    });
    this.checkMetaTags();
  }

  checkMetaTags(): void {
    if (
      environment.serviceEnvironment === 'staging' ||
      environment.serviceEnvironment === 'development'
    ) {
      this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
    }
  }

  trackPage(url: string) {
    const urlTree = this.router.parseUrl(url);
    const queryParams = urlTree.queryParams;
    const pageName = this.getPageName(url);
    const referrer = document.referrer;
    const title = document.title;
    const path = this.router.url;
    const utmCampaign = queryParams['utm_campaign'] || '';
    const utmContent = queryParams['utm_content'] || '';
    const utmMedium = queryParams['utm_medium'] || '';
    const utmSource = queryParams['utm_source'] || '';

    this.analyticsService.trackPageLoaded(
      pageName,
      path,
      referrer,
      title,
      url,
      utmCampaign,
      utmContent,
      utmMedium,
      utmSource
    );
  }

  private getPageName(url: string): string {
    const segments = url.split('/');
    return segments[segments.length - 1] || 'Home';
  }

  checkAgeVerification(): void {
    if (this.ageVerified !== 'true') {
      this.modal.open(AgeVerificationComponent, this.ngbModalOptions);
    }
  }

  async isLanderScreen(): Promise<void> {
    const authSession = await fetchAuthSession();
    if (authSession.userSub) return;
    this.route.queryParams.subscribe(params => {
      const creatorId = params['creatorId'];
      const onOpen = params['onOpen'];
      if (creatorId) {
        const modalRef = this.modal.open(AuthFormComponent);
        modalRef.componentInstance.formType = 'Lander';
        modalRef.componentInstance.isSignUp = true;
        modalRef.componentInstance.creatorId = creatorId;
      } else if (onOpen) {
        const modalRef = this.modal.open(AuthFormComponent);
        modalRef.componentInstance.isSignUp = true;
      }
    });
  }
}
