import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, first, Observable, of, tap } from 'rxjs';

import { ITokenPurchase, ITokensBalance } from '../interfaces/token.interfaces';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokensService {
  private readonly apiUrl = `${environment.apiBaseUrl}`;
  tokensBalanceSubject = new BehaviorSubject<number>(null);
  tokensBalance$ = this.tokensBalanceSubject.asObservable();
  getMoreTokensEvent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private readonly http: HttpClient) {}

  getTokensBalance(): Observable<ITokensBalance> {
    const endpoint = this.apiUrl + `/tokens/balance`;
    return this.http.get<ITokensBalance>(endpoint);
  }

  purchaseTokens(requestBody: ITokenPurchase): Observable<ITokensBalance> {
    const endpoint = this.apiUrl + `/tokens/purchase`;
    return this.http.post<ITokensBalance>(endpoint, requestBody);
  }

  fetchTokensBalance(emitOnlyOnCompletion?: boolean): Observable<ITokensBalance> {
    const balance$ = this.getTokensBalance().pipe(
      first(),
      tap({
        next: (res: ITokensBalance) => {
          this.tokensBalanceSubject.next(res.data.balance);
        },
        error: error => {
          console.error('Unexpected error:', error);
          this.tokensBalanceSubject.next(0);
        }
      })
    );

    if (emitOnlyOnCompletion) {
      return balance$;
    } else {
      balance$.subscribe();
      return of();
    }
  }
}
