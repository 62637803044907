import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxMasonryModule } from 'ngx-masonry';

import { CreatorMediaCardComponent } from 'app/components/creator-media-card/creator-media-card.component';
import { MediaLightboxComponent } from 'app/components/media-lightbox/media-lightbox.component';

import { ICreatorContent, ICreatorData } from 'app/interfaces/creator.interfaces';

import { ECreatorMediaTypes } from 'app/enums/creator.enums';
import { EMediaLightboxUsage } from '../../enums/shared.enums';

@Component({
  selector: 'stxt-masonry-gallery',
  standalone: true,
  imports: [CommonModule, NgxMasonryModule, CreatorMediaCardComponent, MediaLightboxComponent],
  templateUrl: './masonry-gallery.component.html',
  styleUrl: './masonry-gallery.component.scss'
})
export class MasonryGalleryComponent implements OnChanges {
  @Input() mediaContent: ICreatorContent[] = [];
  @Input() userId: string;
  @Input() creator: ICreatorData;
  @Input() showImage: boolean = true;
  @Input() showVideo: boolean = true;
  @Input() showText: boolean = true;
  @Input() postDescription: string = 'posts';
  public selectedMedia: ICreatorContent;
  public ECreatorMediaTypes = ECreatorMediaTypes;
  public mediaFullScreen: boolean = false;
  public mediaList: ICreatorContent[] = [];
  protected readonly EMediaLightboxUsage = EMediaLightboxUsage;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['mediaContent'] ||
      changes['showImage'] ||
      changes['showVideo'] ||
      changes['showText']
    ) {
      this.filterMediaContent();
    }
  }

  private filterMediaContent(): void {
    this.mediaContent = this.mediaContent.filter(item => {
      switch (item.type) {
        case ECreatorMediaTypes.Image:
          return this.showImage;
        case ECreatorMediaTypes.Video:
          return this.showVideo;
        case ECreatorMediaTypes.Text:
          return this.showText;
        default:
          return false;
      }
    });
    this.mediaList = this.mediaContent.filter(
      item => item.type === ECreatorMediaTypes.Image || item.type === ECreatorMediaTypes.Video
    );
  }

  openMediaFullScreen(selectedMedia: ICreatorContent): void {
    if (selectedMedia.type === ECreatorMediaTypes.Text) return;
    this.selectedMedia = selectedMedia;
    this.mediaFullScreen = true;
  }
}
